"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _merchant = require("@/api/merchant");
var _user = require("@/api/iot/user");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _setmeal = require("@/api/iot/setmeal");
var _default = {
  props: ['editItem', 'roleList'],
  components: {
    customPage: _customPage.default
  },
  data: function data() {
    return {
      loading: false,
      dialogVisible: false,
      searchForm: {
        uid: ''
      },
      total: 0,
      tableData: []
    };
  },
  computed: {
    dialogTitle: function dialogTitle() {
      return '用户优惠券';
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this = this;
      if (val) {
        this.$nextTick(function () {
          if (_this.editItem) {
            // 编辑
            for (var key in _this.searchForm) {
              _this.searchForm[key] = _this.editItem[key];
            }
            _this.getList();
          }
        });
      }
    }
  },
  mounted: function mounted() {},
  methods: {
    // 删除
    removeItem: function removeItem(item) {
      var _this2 = this;
      this.$confirm('您确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _setmeal.delSendCoupon)({
          id: item.id
        }).then(function (res) {
          _this2.getList(true);
        });
      }).catch(function () {});
    },
    getList: function getList(flag) {
      var _this3 = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      if (!flag) {
        this.tableData = [];
      }
      this.loading = true;
      (0, _user.getUserCouponList)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        _this3.loading = false;
        _this3.tableData = res.data || [];
        _this3.total = res.total;
      });
    },
    // 检测状态
    chkStatusType: function chkStatusType(status) {
      var type = 'info';
      if (status == 1) {
        type = 'primary';
      } else if (status == 2) {
        type = 'success';
      } else if (status == 3) {
        type = 'danger';
      }
      return type;
    }
  }
};
exports.default = _default;