"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createPlatformAPI = createPlatformAPI;
exports.decryptIDAPI = decryptIDAPI;
exports.getPlatformInfoAPI = getPlatformInfoAPI;
exports.getPlatformListAPI = getPlatformListAPI;
exports.insertOrderInfoAPI = insertOrderInfoAPI;
exports.updateOrderStatusAPI = updateOrderStatusAPI;
exports.updatePlatformAPI = updatePlatformAPI;
exports.useMultiTableAPI = useMultiTableAPI;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/**
 * 浓度统计相关api
 * insertOrderInfo
 **/

/**
 * 创建平台
 * platform_id: string;
 **/
function createPlatformAPI(platform) {
  return _fetch.default.post('/Supplier/createPlatform', platform, true);
}

// 更新平台信息-1
function updatePlatformAPI(platform) {
  return _fetch.default.post('/Supplier/updatePlatform', platform, true);
}
// 获取平台信息
function getPlatformInfoAPI(params) {
  return _fetch.default.post('/Supplier/getPlatformInfo', params);
}
// 获取平台列表-2
function getPlatformListAPI(params) {
  return _fetch.default.post('/Supplier/getPlatformList', params);
}

// 插入订单信息
function insertOrderInfoAPI(platform) {
  return _fetch.default.post('/Supplier/insertOrderInfo', platform, true);
}

/**
 * 使用其他平台的统计数据，直接传一个平台ID即可
 **/
function useMultiTableAPI(platform) {
  return _fetch.default.post('/Supplier/useMultiTable', platform, true);
}
// 更新平台订单状态
function updateOrderStatusAPI(params) {
  return _fetch.default.post('/Supplier/updateOrderStatus', params, true);
}
// 后端解密数据
function decryptIDAPI(params) {
  return _fetch.default.post('/Supplier/decryptID', params, true);
}