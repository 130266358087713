"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cabinetFan = cabinetFan;
exports.cabinetGetList = cabinetGetList;
exports.cabinetSave = cabinetSave;
exports.deviceDel = deviceDel;
exports.getElectricMeterLogList = getElectricMeterLogList;
exports.getElectricMeterStat = getElectricMeterStat;
exports.queryMeter = queryMeter;
exports.querySimCid = querySimCid;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/**
 * 换电柜
 */
// 换电柜列表
function cabinetGetList(params) {
  return _fetch.default.get('/Supplier/device/getList', params);
}
//换电柜修改
function cabinetSave(params) {
  return _fetch.default.post('/Supplier/device/save', params, true);
}
//删除电柜修改
function deviceDel(params) {
  return _fetch.default.post('/Supplier/device/del', params, true);
}

// 换电柜风扇开关
function cabinetFan(params) {
  return _fetch.default.get('/Supplier/device/cabinetFan', params, true);
}
// 查询换电柜电表度数
function queryMeter(params) {
  return _fetch.default.get('/Supplier/device/queryMeter', params, true);
}
// 换电柜电表统计
function getElectricMeterStat(params) {
  return _fetch.default.get('/Supplier/device/getElectricMeterStat', params);
}
// 换电柜电表记录列表
function getElectricMeterLogList(params) {
  return _fetch.default.get('/Supplier/device/getElectricMeterLogList', params);
}

// 查询换电柜sim卡cid
function querySimCid(params) {
  return _fetch.default.get('/Supplier/device/querySimCid', params, true);
}