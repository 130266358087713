"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _toConsumableArray2 = _interopRequireDefault(require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/toConsumableArray.js"));
require("core-js/modules/es6.number.constructor");
var _goodsLink = _interopRequireDefault(require("@/components/goodsLink"));
var _goodsDialog = _interopRequireDefault(require("@/components/goodsDialog.vue"));
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
var _linkDialog = _interopRequireDefault(require("./linkDialog"));
var _default = {
  components: {
    goodsLink: _goodsLink.default,
    goodsDialog: _goodsDialog.default,
    cropUpload: _cropUpload.default,
    linkDialog: _linkDialog.default
  },
  props: {
    contentType: {
      type: String,
      default: 'goods'
    },
    goodsNum: {
      type: Number
    },
    title: {
      type: String
    },
    formData: {
      type: Object,
      default: {}
    },
    type: {
      type: String
    }
  },
  computed: {
    linkVal: function linkVal() {
      return this.formData.jumpType == 1 ? this.formData.jumpUrl : this.formData.goods.title;
    }
  },
  data: function data() {
    return {
      goodsList: [],
      goods: [{
        imgurl: ''
      }, {
        imgurl: ''
      }],
      editItem: null
    };
  },
  methods: {
    // 添加商品
    openSelect: function openSelect() {
      this.$refs.goodsDialog.dialogVisible = true;
      this.$refs.goodsDialog.selectItems = (0, _toConsumableArray2.default)(this.goodsList);
    },
    // 设置选中
    setSelect: function setSelect(goodsList) {
      this.goodsList = goodsList;
    },
    // 升序
    upSort: function upSort(index) {
      if (index === 0) {
        return;
      }
      var item = this.goodsList[index];
      this.$set(this.goodsList, index, this.goodsList[index - 1]);
      this.$set(this.goodsList, index - 1, item);
    },
    // 降序
    downSort: function downSort(index) {
      if (index === this.goodsList.length - 1) {
        return;
      }
      var item = this.goodsList[index];
      this.$set(this.goodsList, index, this.goodsList[index + 1]);
      this.$set(this.goodsList, index + 1, item);
    },
    // 删除商品
    deleteGoods: function deleteGoods(index) {
      this.goodsList.splice(index, 1);
    },
    addLink: function addLink() {
      this.editItem = (0, _objectSpread2.default)({}, this.formData);
      this.$refs.linkDialog.dialogVisible = true;
    },
    setVal: function setVal(_ref) {
      var jumpType = _ref.jumpType,
        jumpUrl = _ref.jumpUrl,
        goods = _ref.goods;
      this.$emit('setVal', {
        jumpType: jumpType,
        jumpUrl: jumpUrl,
        goods: goods
      });
      this.formData.jumpType = jumpType;
      this.formData.jumpUrl = jumpUrl;
      this.formData.goods = jumpType == 2 ? goods : {};
    }
  }
};
exports.default = _default;