"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _colForm = _interopRequireDefault(require("../components/colForm"));
var _default = {
  components: {
    colForm: _colForm.default
  },
  data: function data() {
    return {
      configData: {
        backgroundImg: '',
        title: '',
        subtitle: '',
        jumpUrl: '',
        min_price: ''
      }
    };
  }
};
exports.default = _default;