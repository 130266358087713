"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _user = require("@/api/iot/user");
var _default = {
  props: ['editItem', 'roleList'],
  data: function data() {
    return {
      dialogVisible: false,
      form: {
        id: 0,
        uid: 0,
        iot_deposit: 0,
        need_deposit: 0
      },
      rules: {
        need_deposit: [{
          required: true,
          message: '请输入需付押金',
          trigger: 'change'
        }, {
          type: 'number',
          min: 0,
          message: '需付押金不能小于0',
          trigger: 'change'
        }]
      }
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this = this;
      if (val) {
        this.$nextTick(function () {
          if (_this.editItem) {
            // 编辑
            for (var key in _this.form) {
              if (_this.editItem[key]) {
                _this.form[key] = _this.editItem[key];
              }
            }
          }
        });
      } else {
        this.reset();
      }
    }
  },
  methods: {
    reset: function reset() {
      this.form = this.$options.data().form;
      this.$refs['form'].resetFields();
    },
    submit: function submit() {
      var _this2 = this;
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          (0, _user.changeDeposit)((0, _objectSpread2.default)({}, _this2.form)).then(function (res) {
            _this2.$emit('getList');
            _this2.dialogVisible = false;
          });
        } else {
          _this2.$message({
            message: '请检查表单错误',
            type: 'error'
          });
          return false;
        }
      });
    }
  }
};
exports.default = _default;