"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/toConsumableArray.js"));
var _objectSpread2 = _interopRequireDefault(require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _base = _interopRequireDefault(require("./child/base"));
var _meal = _interopRequireDefault(require("./child/meal"));
var _goods = require("@/api/goods");
var _default = {
  components: {
    baseInfo: _base.default,
    mealInfo: _meal.default
  },
  data: function data() {
    return {
      activeName: "base",
      mealTabs: 1,
      isRemove: false,
      count: 0,
      x: 0,
      y: 0,
      timer: null
    };
  },
  props: ['draftId'],
  watch: {
    activeName: function activeName(val, oldVal) {
      // console.log(val, oldVal);
      // 切换标签滚动条回到顶部
      document.getElementsByClassName('app-main')[0].scrollTop = 0;
      if (!this.isRemove) {
        var oldIndex = oldVal.substring(4);
        if (this.$refs.mealInfo) {
          this.$refs.mealInfo.saveStorage(oldIndex);
        }
      } else {
        this.isRemove = false;
      }
    }
  },
  computed: {
    currentMeal: function currentMeal() {
      return this.activeName.substring(4);
    }
  },
  mounted: function mounted() {
    var _this = this;
    if (sessionStorage.getItem('mealInfo')) {
      this.mealTabs = JSON.parse(sessionStorage.getItem('mealInfo')).length;
    }
    // 监听鼠标
    document.onmousemove = function (event) {
      var x1 = event.clientX;
      var y1 = event.clientY;
      if (_this.x !== x1 || _this.y !== y1) {
        _this.count = 0;
      }
      _this.x = x1;
      _this.y = y1;
    };
    // 监听键盘
    document.onkeydown = function () {
      _this.count = 0;
    };
    // 监听Scroll
    document.onscroll = function () {
      _this.count = 0;
    };
    this.setTimer();
  },
  methods: {
    clearTimer: function clearTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
    setTimer: function setTimer() {
      this.count = 0;
      if (!this.timer) {
        this.timer = setInterval(this.cookieTimeout, 1000);
      }
    },
    cookieTimeout: function cookieTimeout() {
      this.count += 1;
      if (this.count === 5 * 60) {
        this.saveToDraft();
      }
      if (this.count > 1000) {
        this.count = 11;
      }
    },
    saveMealItem: function saveMealItem(submitForm, monthList, dayList, customList) {
      var id = sessionStorage.getItem("goodsId");
      var status = sessionStorage.getItem('goodsStatus');
      if (!submitForm.title) {
        this.$message({
          message: "套餐名字不能为空",
          type: "warning"
        });
        return;
      }
      var params = (0, _objectSpread2.default)((0, _objectSpread2.default)({
        goods_id: id,
        spec: []
      }, submitForm), {}, {
        status: status,
        operation_type: 1
      });
      if (submitForm.lease_set == 1) {
        params.spec = (0, _toConsumableArray2.default)(monthList);
      } else if (submitForm.lease_set == 2) {
        params.spec = (0, _toConsumableArray2.default)(dayList);
      } else {
        params.spec = (0, _toConsumableArray2.default)(customList);
      }
      // 存在套餐id，编辑套餐
      (0, _goods.editGoodsSetmeal)(params).then(function (res) {});
    },
    saveMealInfo: function saveMealInfo() {
      var mealInfo = sessionStorage.getItem('mealInfo') ? JSON.parse(sessionStorage.getItem('mealInfo')) : null;
      if (this.$refs.mealInfo && mealInfo) {
        // 当前页为套餐tab
        for (var index in mealInfo) {
          if (index != this.currentMeal - 1) {
            var _mealInfo$index = mealInfo[index],
              formData = _mealInfo$index.formData,
              monthList = _mealInfo$index.monthList,
              dayList = _mealInfo$index.dayList,
              customList = _mealInfo$index.customList;
            this.saveMealItem(formData, monthList, dayList, customList);
          }
        }
        this.$refs.mealInfo.saveToDraft();
      } else if (this.$refs.mealInfo) {
        this.$refs.mealInfo.saveToDraft();
      } else if (mealInfo) {
        // 当前为基础信息tab
        for (var _index in mealInfo) {
          var _mealInfo$_index = mealInfo[_index],
            _formData = _mealInfo$_index.formData,
            _monthList = _mealInfo$_index.monthList,
            _dayList = _mealInfo$_index.dayList,
            _customList = _mealInfo$_index.customList;
          this.saveMealItem(_formData, _monthList, _dayList, _customList);
        }
      }
    },
    saveBaseInfo: function saveBaseInfo(submitForm, goodsParams) {
      var _this2 = this;
      var id = sessionStorage.getItem("goodsId");
      var status = sessionStorage.getItem('goodsStatus');
      if (!submitForm.category_id || !submitForm.goods_name) {
        this.$message({
          message: "商品品类和商品名称不能为空",
          type: "warning"
        });
        return;
      }
      var data = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, submitForm), {}, {
        status: status,
        operation_type: 1
      });
      data.rent_label = data.rent_label.join(',');
      data.province_id = data.province && data.province[0] ? data.province[0] : "";
      data.show_ity = data.province && data.province[1] ? data.province[1] : "";
      data.carousel_img = data.carousel_img.join(',');
      data.custom = goodsParams.map(function (item) {
        return {
          'field_title': item.field_title,
          'field_val': item.field_type != 3 ? item.field_val : item.field_val.join(',')
        };
      });
      delete data.province;
      if (!id) {
        // 没有商品id，新增
        (0, _goods.addGoodsData)(data).then(function (res) {
          sessionStorage.setItem('goodsId', res.goods_id);
          _this2.saveMealInfo();
        });
      } else {
        // 有商品id，编辑
        data.goods_id = id;
        (0, _goods.editGoodsData)(data).then(function (res) {
          _this2.saveMealInfo();
        });
      }
    },
    saveToDraft: function saveToDraft() {
      // 保存到草稿箱
      if (this.$refs.baseInfo) {
        this.$refs.baseInfo.saveToDraft();
      }
      if (this.$refs.mealInfo) {
        this.$refs.mealInfo.saveToDraft();
      }
      // const baseInfo = sessionStorage.getItem('baseInfo') ? JSON.parse(sessionStorage.getItem('baseInfo')) : null;
      // if (this.$refs.baseInfo) {
      //   const { submitForm, goodsParams } = this.$refs.baseInfo;
      //   this.saveBaseInfo(submitForm, goodsParams);
      // } else if (baseInfo) {
      //   const { formData, goodsParams } = baseInfo;
      //   this.saveBaseInfo(formData, goodsParams)
      // }
    },
    removeTab: function removeTab(index) {
      var _this3 = this;
      if (this.mealTabs === 1) {
        this.$message({
          message: "套餐不能为空",
          type: "warning"
        });
        return;
      }
      this.$confirm('您确定要删除该套餐信息吗?', '删除套餐信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        _this3.isRemove = true;
        var list = JSON.parse(sessionStorage.getItem('mealInfo'));
        if (list[index] && list[index].formData.setmeal_id) {
          (0, _goods.deleteGoodsSetmeal)({
            goods_id: sessionStorage.getItem("goodsId"),
            setmeal_id: list[index].formData.setmeal_id
          }).then(function (res) {
            list.splice(index, 1);
            _this3.mealTabs = list.length;
            sessionStorage.setItem('mealInfo', JSON.stringify((0, _toConsumableArray2.default)(list)));
            if (index === 0) {
              _this3.activeName = 'base';
            } else {
              _this3.activeName = 'meal' + index;
            }
          });
        } else {
          list.splice(index, 1);
          _this3.mealTabs = list.length;
          sessionStorage.setItem('mealInfo', JSON.stringify((0, _toConsumableArray2.default)(list)));
          if (index === 0) {
            _this3.activeName = 'base';
          } else {
            _this3.activeName = 'meal' + index;
          }
        }
      }).catch(function () {});
    },
    setTab: function setTab(val) {
      this.activeName = val;
    },
    addTab: function addTab() {
      this.mealTabs += 1;
      this.activeName = 'meal' + this.mealTabs;
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.clearTimer();
  },
  destroyed: function destroyed() {
    sessionStorage.removeItem("baseInfo");
    sessionStorage.removeItem("goodsId");
    sessionStorage.removeItem("mealInfo");
    sessionStorage.removeItem("goodsStatus");
  }
};
exports.default = _default;