"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _system = require("@/api/system");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _save = _interopRequireDefault(require("./dialog/save"));
var _vuex = require("vuex");
var _default = {
  components: {
    customPage: _customPage.default,
    addDialog: _save.default
  },
  data: function data() {
    return {
      loading: false,
      searchForm: {},
      total: 0,
      tableData: [],
      editItem: null
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList(flag) {
      var _this = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      if (!flag) {
        this.tableData = [];
      }
      this.loading = true;
      (0, _system.getWechatPayList)({
        page: page,
        pageSize: pageSize
      }).then(function (res) {
        _this.loading = false;
        _this.tableData = res.data || [];
        _this.total = res.total;
      });
    },
    // 删除
    removeItem: function removeItem(item) {
      var _this2 = this;
      this.$confirm('是否删除当前支付渠道', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      }).then(function () {
        (0, _system.delWechatPay)(item.id).then(function (res) {
          _this2.getList(true);
        });
      }).catch(function () {});
    },
    // 添加/编辑账号
    openAddDialog: function openAddDialog() {
      var val = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.editItem = val;
      this.$refs.addDialog.dialogVisible = true;
    },
    // 查询
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      this.searchForm = this.$options.data().searchForm;
      this.getList();
    },
    // 提交修改
    editWechatPay: function editWechatPay(item) {
      var _this3 = this;
      (0, _system.editWechatPay)({
        form_data: item
      }).then(function (res) {
        _this3.getList(true);
      });
    }
  }
};
exports.default = _default;