"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.editPwd = editPwd;
exports.forgetPwd = forgetPwd;
exports.getAuthRule = getAuthRule;
exports.login = login;
exports.logout = logout;
exports.merchantEditPwd = merchantEditPwd;
exports.merchantForgetPwd = merchantForgetPwd;
exports.merchantLogin = merchantLogin;
exports.merchantLogout = merchantLogout;
exports.merchantTokenLogin = merchantTokenLogin;
exports.sendMobileCode = sendMobileCode;
exports.supplierTokenLogin = supplierTokenLogin;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
function login(params) {
  return _fetch.default.post("/Supplier/Login", params);
}
function logout() {
  return _fetch.default.get("/Supplier/LoginOut");
}

// 获取用户菜单权限
function getAuthRule() {
  return _fetch.default.post("/Supplier/Auth/getAuthRule");
}

// 获取验证码
function sendMobileCode(mobile) {
  return _fetch.default.post("/Supplier/Verify/sendCode", {
    mobile: mobile
  }, true);
}

// 编辑密码
function editPwd(params) {
  return _fetch.default.post("/Supplier/Auth/editPwd", params);
}

// 忘记密码
function forgetPwd(params) {
  return _fetch.default.post("/Supplier/Login/forgetPwd", params);
}

// 商户登录
function merchantLogin(params) {
  return _fetch.default.post("/Supplier/merchantLogin", params);
}
function merchantLogout() {
  return _fetch.default.get("/Supplier/merchantLoginOut");
}

// 编辑密码
function merchantEditPwd(params) {
  return _fetch.default.post("/Supplier/Auth/merchantEditPwd", params);
}

// 忘记密码
function merchantForgetPwd(params) {
  return _fetch.default.post("/Supplier/Login/merchantForgetPwd", params);
}

// 供应商token登录
function supplierTokenLogin(params) {
  return _fetch.default.post("/Supplier/supplierTokenLogin", params);
}

// 商户token登录
function merchantTokenLogin(params) {
  return _fetch.default.post("/Supplier/merchantTokenLogin", params);
}