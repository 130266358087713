"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _finance = require("@/api/finance");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _default = {
  components: {
    customPage: _customPage.default
  },
  data: function data() {
    return {
      searchForm: {
        user_id: "",
        trans_type: "",
        status: ""
      },
      amount: 0,
      service_fee: 0,
      total: 0,
      tableData: [],
      editItem: {},
      riskList: [{
        id: '1',
        name: '正常'
      }, {
        id: '2',
        name: '已退款'
      }],
      merchantList: [],
      transTypeList: [{
        rate_value: 1,
        rate_name: '推广分红'
      }, {
        rate_value: 2,
        rate_name: '提现'
      }]
    };
  },
  mounted: function mounted() {
    if (this.$route.params.userId) {
      this.searchForm.user_id = this.$route.params.userId;
    }
    this.getFromSearch();
    this.getList();
  },
  methods: {
    getList: function getList(flag) {
      var _this = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      if (!flag) {
        this.tableData = [];
      }
      (0, _finance.getMemberFlowList)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        _this.tableData = res.data || [];
        _this.total = res.total;
        _this.service_fee = res.service_fee;
        _this.amount = res.amount;
        // console.log(this.tableData.length)
      });
    },
    goToOrder: function goToOrder(orderId) {
      if (orderId) {
        this.$router.push({
          name: 'orderList',
          params: {
            orderId: orderId
          }
        });
      }
    },
    goToOrderpUserId: function goToOrderpUserId(pUserId) {
      if (pUserId) {
        this.$router.push({
          name: 'orderList',
          params: {
            pUserId: pUserId
          }
        });
      }
    },
    // 查询
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      var searchForm = this.$options.data().searchForm;
      this.searchForm = (0, _objectSpread2.default)({}, searchForm);
      this.getList();
    },
    getFromSearch: function getFromSearch() {}
  }
};
exports.default = _default;