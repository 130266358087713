import { render, staticRenderFns } from "./add.vue?vue&type=template&id=507a06a9&scoped=true&"
import script from "./add.vue?vue&type=script&lang=js&"
export * from "./add.vue?vue&type=script&lang=js&"
import style0 from "./add.vue?vue&type=style&index=0&id=507a06a9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "507a06a9",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\ssz\\xxz2\\battery_iot\\iot_vehicle\\iot_frontend\\iot_supplier\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('507a06a9')) {
      api.createRecord('507a06a9', component.options)
    } else {
      api.reload('507a06a9', component.options)
    }
    module.hot.accept("./add.vue?vue&type=template&id=507a06a9&scoped=true&", function () {
      api.rerender('507a06a9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/system/merchant/role/add.vue"
export default component.exports