"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _apayment = require("@/api/apayment");
var _default = {
  props: ['selectedItem'],
  data: function data() {
    return {
      dialogVisible: false,
      formData: {
        id: 0,
        name: '',
        account: '',
        amount: '',
        resaon: ''
      },
      defaultForm: {
        evidence_url: '',
        merchant_remark: ''
      },
      rules: {
        evidence_url: [{
          required: true,
          message: '请上传图片',
          trigger: 'change'
        }]
      }
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.formData = (0, _objectSpread2.default)({}, this.selectedItem);
      } else {
        this.$refs['formData'].resetFields();
      }
    }
  },
  methods: {
    submit: function submit() {
      var _this = this;
      // this.$confirm("您确定要申请扣款吗？", "申请扣款", {
      // 	confirmButtonText: '确定',
      // 	cancelButtonText: '取消'
      // }).then(() => {
      this.$refs['formData'].validate(function (valid) {
        if (valid) {
          var param = (0, _objectSpread2.default)({}, _this.formData);
          (0, _apayment.editAPaymentData)(param).then(function (res) {
            _this.$emit('getList');
            _this.close();
          }).catch(function () {});
        }
      });
    },
    close: function close() {
      this.dialogVisible = false;
    }
  }
};
exports.default = _default;