"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.adminCheckPass = adminCheckPass;
exports.adminCheckRefuse = adminCheckRefuse;
exports.depositPayList = depositPayList;
exports.depositRefund = depositRefund;
exports.getMerchantAndShareMerchantListByUser = void 0;
exports.getQosList = getQosList;
exports.getQosTypeList = getQosTypeList;
exports.handleQosState = handleQosState;
exports.orderRefundDetail = orderRefundDetail;
exports.orderRefundInfo = orderRefundInfo;
exports.orderRefundList = orderRefundList;
exports.orderRefundTransData = orderRefundTransData;
exports.shopCheckPass = shopCheckPass;
exports.shopCheckRefuse = shopCheckRefuse;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/**
 * 初始化运营分析相关接口
 **/

// 订单退款列表
function orderRefundList(params) {
  return _fetch.default.post('/Supplier/OrderRefund/orderRefundList', params);
}
// 押金支付列表
function depositPayList(params) {
  return _fetch.default.post('/Supplier/deposit/getDeposit', params);
}
// 押金退款
function depositRefund(params) {
  return _fetch.default.post('/Supplier/deposit/refundDepositReview', params);
}

// 问题列表
function getQosList(params) {
  return _fetch.default.get('/Supplier/Person/getQosList', params);
}
// 问题类型
function getQosTypeList(params) {
  return _fetch.default.get('/Supplier/Person/getQosTypeList', params);
}
// 处理问题
function handleQosState(params) {
  return _fetch.default.post('/Supplier/Person/handleQosState', params);
}
// 订单退款详情
function orderRefundInfo(id) {
  return _fetch.default.get('/Supplier/OrderRefund/orderRefundInfo', {
    order_id: id
  });
}
// 退款订单补足金额明细
function orderRefundTransData(id) {
  return _fetch.default.get('/Supplier/OrderRefund/orderRefundTransData', {
    refund_id: id
  });
}
// 退款订单明细查看详情
function orderRefundDetail(id) {
  return _fetch.default.get('/Supplier/OrderRefund/orderRefundDetail', {
    refund_id: id
  });
}
// 商户审核通过
function shopCheckPass(id) {
  return _fetch.default.post('/Supplier/OrderRefund/shopCheckPass', {
    refund_id: id
  }, true);
}
// 商户审核拒绝
function shopCheckRefuse(params) {
  return _fetch.default.post('/Supplier/OrderRefund/shopCheckRefuse', params, true);
}
// 平台审核通过
function adminCheckPass(id) {
  return _fetch.default.post('/Supplier/OrderRefund/adminCheckPass', {
    refund_id: id
  }, true);
}
// 平台审核拒绝
function adminCheckRefuse(params) {
  return _fetch.default.post('/Supplier/OrderRefund/adminCheckRefuse', params, true);
}
var getMerchantAndShareMerchantListByUser = function getMerchantAndShareMerchantListByUser(params) {
  return _fetch.default.post('/zwt/merchantcashservicenet/getMerchantAndShareMerchantListByUser', params);
};
exports.getMerchantAndShareMerchantListByUser = getMerchantAndShareMerchantListByUser;