"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bindBattery = bindBattery;
exports.changeDeposit = changeDeposit;
exports.changeRent = changeRent;
exports.freezeSave = freezeSave;
exports.getEsignContract = getEsignContract;
exports.getSelectMemberList = getSelectMemberList;
exports.getUserCouponList = getUserCouponList;
exports.getUserRentList = getUserRentList;
exports.returnBattery = returnBattery;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/**
 * 用户管理
 */
// 获取下拉用户列表
function getSelectMemberList(params) {
  return _fetch.default.get('/Supplier/User/getSelectMemberList', params);
}
// 获取用户租借列表
function getUserRentList(params) {
  return _fetch.default.get('/Supplier/User/getUserRentList', params);
}
// 后台手动归还电池
function returnBattery(params) {
  return _fetch.default.post('/Supplier/User/returnBattery', params, true);
}
// 后台手动绑定电池
function bindBattery(params) {
  return _fetch.default.post('/Supplier/User/bindBattery', params, true);
}
// 获取易签宝签约合同
function getEsignContract(params) {
  return _fetch.default.get('/Supplier/User/getEsignContract', params);
}
// 修改租期
function changeRent(params) {
  return _fetch.default.post('/Supplier/User/changeRent', params, true);
}
// 修改押金
function changeDeposit(params) {
  return _fetch.default.post('/Supplier/User/changeDeposit', params, true);
}
// 获取用户优惠券列表
function getUserCouponList(params) {
  return _fetch.default.get('/Supplier/User/getUserCouponList', params);
}

/**
 * 用户冻结处理
 **/
function freezeSave(params) {
  return _fetch.default.post('/Supplier/User/freezeSave', params);
}