"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.branchDel = branchDel;
exports.branchGetList = branchGetList;
exports.branchSave = branchSave;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/**
 * 运营网点
 */
// 运营网点列表
function branchGetList(params) {
  return _fetch.default.get("/Supplier/branch/getList", params);
}
// 运营网点修改或添加
function branchSave(params) {
  return _fetch.default.post("/Supplier/branch/save", params, true);
}
// 运营网点修改或添加
function branchDel(params) {
  return _fetch.default.post("/Supplier/branch/del", params, true);
}