"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _typeof2 = _interopRequireDefault(require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/typeof.js"));
var _router = _interopRequireDefault(require("@/router"));
var _qs = _interopRequireDefault(require("qs"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var $h5 = {};

// url内部跳转
$h5.href = function (param) {
  var isBlank = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (!param) {
    return;
  }
  if (param < 0) {
    _router.default.back(param);
    return;
  }
  var routerParam = {};
  if ((0, _typeof2.default)(param) == 'object') {
    routerParam = param;
  } else if (typeof param == 'string') {
    var str = param.trim();
    if (str.indexOf('/') == -1) {
      routerParam.name = str;
    } else {
      routerParam.path = str;
    }
  }
  if (isBlank) {
    var routeData = _router.default.resolve(routerParam);
    window.open(routeData.href, '_blank');
  } else {
    _router.default.push(routerParam);
  }
};

// url内部重定向跳转
$h5.redirect = function (param) {
  if (!param) {
    return;
  }
  var routerParam = {};
  if ((0, _typeof2.default)(param) == 'object') {
    routerParam = param;
  } else if (typeof param == 'string') {
    var str = param.trim();
    if (str.indexOf('/') == -1) {
      routerParam.name = str;
    } else {
      routerParam.path = str;
    }
  }
  _router.default.replace(routerParam);
};

// url外部跳转
$h5.openUrl = function () {
  var href = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var isBlank = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (!href) {
    return;
  }
  var url;
  if ((0, _typeof2.default)(href) == 'object') {
    url = $h5.filterUrl(href.url, href.query);
  } else {
    url = $h5.filterUrl(href);
  }
  console.log('openUrl', url);
  if (isBlank) {
    window.open(url, '_blank');
  } else {
    window.location.href = url;
  }
};

// 过滤/组装url
$h5.filterUrl = function (url) {
  var query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  if (query) {
    var end = '';
    var queryStr = _qs.default.stringify(query);
    if (url.indexOf('#') > -1) {
      url = url.replace('#', '');
      end = '#';
    }
    if (url.indexOf('?') > -1) {
      url += '&';
    } else {
      url += '?';
    }
    url += queryStr + end;
  }
  return url;
};
$h5.setCookies = function (key, data) {
  // console.log('setCookies', data)
  // console.log('typeof data', typeof data)
  if ((0, _typeof2.default)(data) == 'object' || typeof data == 'array') {
    data = JSON.stringify(data);
    // console.log('data', data)
  }

  _jsCookie.default.set(key, data);
};
$h5.getCookies = function (key) {
  var data = _jsCookie.default.get(key);
  // console.log('getCookies', data)
  try {
    data = JSON.parse(data) || data;
  } catch (_unused) {}
  // console.log('data', data)
  return data;
};
$h5.removeCookies = function (key) {
  _jsCookie.default.remove(key);
};
var _default = $h5;
exports.default = _default;