"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _audit = require("@/api/audit");
var _default = {
  props: ['editId'],
  data: function data() {
    return {
      isLoading: false,
      form: {
        remark: ''
      },
      rules: {
        remark: [{
          required: true,
          message: '原因不能为空',
          trigger: 'blur'
        }]
      },
      dialogVisible: false
    };
  },
  methods: {
    submit: function submit() {
      var _this = this;
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          _this.isLoading = true;
          (0, _audit.merchantWithdrawalRefuse)((0, _objectSpread2.default)({
            id: _this.editId
          }, _this.form)).then(function (res) {
            _this.isLoading = false;
            _this.dialogVisible = false;
            _this.$emit('getList', true);
          });
        } else {
          _this.$message({
            message: "请检查表单错误",
            type: "error"
          });
          return false;
        }
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (!val) {
        this.$refs['ruleForm'].resetFields();
        this.form.remark = '';
      }
    }
  }
};
exports.default = _default;