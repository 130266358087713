"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.delSettleById = delSettleById;
exports.delSupplierById = delSupplierById;
exports.getSettleList = getSettleList;
exports.getSupplierList = getSupplierList;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/**
 * 用户管理-用户列表
 */
// 获取入驻商户列表
function getSettleList(params) {
  return _fetch.default.get('/Supplier/Settle/getSettleList', params);
}

// 删除入驻商户
function delSettleById(params) {
  return _fetch.default.post('/Supplier/Settle/delSettleById', params);
}
// 供应商入驻
function getSupplierList(params) {
  return _fetch.default.get('/Supplier/Settle/getSupplierList', params);
}

// 删除入驻商户
function delSupplierById(params) {
  return _fetch.default.post('/Supplier/Settle/delSupplierById', params);
}