"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
var _web = require("@/api/web");
var _article = require("@/api/article");
var _textEditor = _interopRequireDefault(require("@/components/textEditor"));
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
var _default = {
  props: ["type", "editId"],
  components: {
    textEditor: _textEditor.default,
    cropUpload: _cropUpload.default
  },
  data: function data() {
    return {
      list_Level1: [],
      list_Level2: [],
      articleForm: {
        article_title: "",
        keword: "",
        parentid: "",
        type_id: "",
        article_img: "",
        abstract: "",
        status: 1,
        content: "",
        recommend: ["home"],
        home_recommend: "1",
        hot_recommend: "2",
        author: "",
        platform: ["pc", "h5", "xcx"]
      },
      rules: {
        article_title: [{
          required: true,
          message: "请输入文章标题",
          trigger: "blur"
        }],
        parentid: [{
          required: true,
          message: "请选择一级分类",
          trigger: "change"
        }],
        type_id: [{
          required: true,
          message: "请选择二级分类",
          trigger: "change"
        }],
        article_img: [{
          required: true,
          message: "请上传文章缩略图",
          trigger: "change"
        }],
        abstract: [{
          required: true,
          message: "请输入文章摘要",
          trigger: "blur"
        }],
        content: [{
          required: true,
          message: "请输入文章内容",
          trigger: "change"
        }],
        recommend: [{
          // type: "array",
          required: true,
          message: "请选择推荐项",
          trigger: "change"
        }],
        status: [{
          required: true,
          message: "请选择发布状态",
          trigger: "change"
        }],
        author: [{
          required: true,
          message: "请输入作者",
          trigger: "blur"
        }],
        platform: [{
          required: true,
          message: "请选择展示平台",
          trigger: "change"
        }]
      }
    };
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    console.log('beforeRouteEnter', from);
    if (from.path == '/') {
      next(function (vm) {
        vm.$router.push({
          name: "articlePlatform"
        });
      });
    } else if (to.name === "articlePlatformAdd") {
      to.meta.title = '文章';
      to.meta.activeMenu = from.path;
      to.meta.parentTitle = from.meta.title;
      to.meta.parentRouteName = from.name;
    }
    next();
  },
  mounted: function mounted() {
    this.init();
    this.queryLevel1();
  },
  methods: {
    init: function init() {
      this.setMeta();
    },
    setMeta: function setMeta() {
      if (this.editId > 0) {
        this.$route.meta.title += "编辑";
        this.getEdit();
      } else {
        this.$route.meta.title += "发布";
      }
    },
    queryLevel1: function queryLevel1() {
      var _this = this;
      (0, _web.getInfoTypeList)({
        type: this.type
      }).then(function (res) {
        _this.list_Level1 = res;
        if (_this.list_Level1.length == 1) {
          _this.articleForm.parentid = _this.list_Level1[0].id;
          _this.changeLevel1(_this.list_Level1[0].id);
        }
      });
    },
    searchSecond: function searchSecond(id) {
      var _this2 = this;
      (0, _article.searchSecondCategory)({
        id: id,
        type: this.type
      }).then(function (res) {
        _this2.list_Level2 = res;
        if (_this2.list_Level2.length == 1) {
          _this2.articleForm.type_id = _this2.list_Level2[0].id;
        }
      });
    },
    changeLevel1: function changeLevel1(val) {
      this.list_Level2 = [];
      this.articleForm.type_id = "";
      this.searchSecond(val);
    },
    getEdit: function getEdit() {
      var _this3 = this;
      (0, _article.searchArticle)(this.editId).then(function (res) {
        if (res) {
          res.recommend = [];
          res.platform = [];
          _this3.searchSecond(res.parentid);
          _this3.articleForm = res;
          var temp_recom = "";
          if (res.home_recommend == 1 && res.hot_recommend == 1) {
            temp_recom = ["home", "hot"];
          } else if (res.home_recommend == 1) {
            temp_recom = ["home"];
          } else if (res.hot_recommend == 1) {
            temp_recom = ["hot"];
          } else {
            temp_recom = [];
          }
          _this3.articleForm.recommend = temp_recom;
          if (res.h5_show == 1) {
            _this3.articleForm.platform.push('h5');
          }
          if (res.pc_show == 1) {
            _this3.articleForm.platform.push('pc');
          }
          if (res.xcx_show == 1) {
            _this3.articleForm.platform.push('xcx');
          }
        }
      });
    },
    changeRadio: function changeRadio(val) {
      this.articleForm.status = val;
    },
    changeCheckBox: function changeCheckBox(val) {
      if (val.length == 2) {
        this.articleForm.home_recommend = "1";
        this.articleForm.hot_recommend = "1";
      } else if (val.length == 1) {
        this.articleForm.home_recommend = val[0] == "home" ? "1" : "2";
        this.articleForm.hot_recommend = val[0] == "hot" ? "1" : "2";
      }
    },
    submitForm: function submitForm(formName) {
      var _this4 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var recommend = _this4.articleForm;
          if (recommend.length == 2) {
            _this4.articleForm.home_recommend = "1";
            _this4.articleForm.hot_recommend = "1";
          } else if (recommend.length == 1) {
            _this4.articleForm.home_recommend = recommend[0] == "home" ? "1" : "2";
            _this4.articleForm.hot_recommend = recommend[0] == "hot" ? "1" : "2";
          }
          _this4.articleForm.h5_show = _this4.articleForm.platform.indexOf('h5') > -1 ? 1 : 2;
          _this4.articleForm.pc_show = _this4.articleForm.platform.indexOf('pc') > -1 ? 1 : 2;
          _this4.articleForm.xcx_show = _this4.articleForm.platform.indexOf('xcx') > -1 ? 1 : 2;
          var newForm = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this4.articleForm), {}, {
            type: _this4.type
          });
          // console.log("newForm", newForm);
          delete newForm.platform;
          delete newForm.recommend;
          (0, _article.addArticle)(newForm).then(function (res) {
            _this4.$router.back();
          });
        }
      });
    },
    resetForm: function resetForm(formName) {
      if (this.type === "edit") {
        this.$route.meta.title = "平台文章编辑";
        this.getEdit();
      } else {
        this.$refs[formName].resetFields();
        // this.articleForm.content = "";
      }
    },
    onEditorChange: function onEditorChange(val) {
      this.articleForm.content = val;
    }
  }
};
exports.default = _default;