"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.batteryTypeDel = batteryTypeDel;
exports.batteryTypeList = batteryTypeList;
exports.batteryTypeSave = batteryTypeSave;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/**
 * 电池类型列表
 */
function batteryTypeList(params) {
  return _fetch.default.get("/Supplier/batteryType/getList", params);
}
//
function batteryTypeSave(params) {
  return _fetch.default.post("/Supplier/batteryType/save", params, true);
}

//
function batteryTypeDel(params) {
  return _fetch.default.post("/Supplier/batteryType/del", params, true);
}