"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _createForOfIteratorHelper2 = _interopRequireDefault(require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/createForOfIteratorHelper.js"));
require("core-js/modules/es6.regexp.split");
var _merchant = require("@/api/merchant");
var _default = {
  props: ["editItem", "menuData"],
  data: function data() {
    return {
      dialogVisible: false,
      form: {
        id: 0,
        title: "",
        rules: ""
      },
      rules: {
        title: [{
          required: true,
          message: '角色名称不能为空',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    dialogTitle: function dialogTitle() {
      return this.editItem ? "编辑角色" : "添加角色";
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this = this;
      if (val) {
        this.$nextTick(function () {
          _this.$refs['form'].resetFields();
          if (_this.editItem) {
            // 编辑
            _this.form.id = _this.editItem.id;
            _this.form.title = _this.editItem.title;
            _this.form.rules = _this.editItem.rules;
            var list = _this.editItem.rules.split(',');
            _this.$refs.tree.setCheckedKeys(list);
          }
        });
      } else {
        this.$refs.tree.setCheckedKeys([]);
        this.form = this.$options.data().form;
      }
    }
  },
  methods: {
    checkClick: function checkClick(data, checkObj) {
      var check = checkObj.checkedKeys.indexOf(data.id) > -1;
      // 如果有子节点，勾选子节点
      if (check && data.children) {
        this.checkChildren(data.children);
      }
    },
    checkChange: function checkChange(data, check) {
      // 父节点操作
      if (data.pid != 0) {
        if (check === true) {
          // 如果选中，设置父节点为选中
          this.$refs.tree.setChecked(data.pid, true);
        } else {
          // 如果取消选中，检查父节点是否该取消选中（可能仍有子节点为选中状态）
          var parentNode = this.$refs.tree.getNode(data.pid);
          var parentHasCheckedChild = false;
          for (var i = 0, parentChildLen = parentNode.childNodes.length; i < parentChildLen; i++) {
            if (parentNode.childNodes[i].checked === true) {
              parentHasCheckedChild = true;
              break;
            }
          }
          if (!parentHasCheckedChild) this.$refs.tree.setChecked(data.pid, false);
        }
      }
      // 子节点操作，如果取消选中，取消子节点选中
      if (data.children != null && check === false) {
        for (var j = 0, len = data.children.length; j < len; j++) {
          var childNode = this.$refs.tree.getNode(data.children[j].id);
          if (childNode.checked === true) {
            this.$refs.tree.setChecked(childNode.data.id, false);
          }
        }
      }
    },
    checkChildren: function checkChildren(childList) {
      var _iterator = (0, _createForOfIteratorHelper2.default)(childList),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          this.$refs.tree.setChecked(item.id, true);
          if (item.children) {
            this.checkChildren(item.children);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    },
    submit: function submit() {
      var _this2 = this;
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          var selectList = _this2.$refs.tree.getCheckedKeys();
          if (selectList.length === 0) {
            _this2.$message({
              message: "角色授权不能为空",
              type: "warning"
            });
          } else {
            _this2.form.rules = selectList.join(',');
            var param = {
              id: _this2.id,
              formData: (0, _objectSpread2.default)({}, _this2.form)
            };
            (0, _merchant.editMerchantGroup)(param).then(function (res) {
              _this2.dialogVisible = false;
              _this2.$emit("getList");
            });
          }
        } else {
          _this2.$message({
            message: "请检查表单错误",
            type: "error"
          });
          return false;
        }
      });
    }
  }
};
exports.default = _default;