"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _add = _interopRequireDefault(require("./add"));
var _xcx = require("@/api/xcx");
var _default = {
  components: {
    customPage: _customPage.default,
    addDialog: _add.default
  },
  data: function data() {
    return {
      searchForm: {
        name: '',
        status: ''
      },
      total: 0,
      tableData: [],
      editItem: null
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    // 删除
    removeItem: function removeItem(id) {
      var _this = this;
      this.$confirm('您确定要删除推广分类吗？', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _xcx.setCategoryDel)(id).then(function (res) {
          _this.getList(true);
        });
      }).catch(function () {});
    },
    // 显示/隐藏
    changeStatus: function changeStatus(id, status) {
      var _this2 = this;
      var tip = "",
        title = "";
      if (status == 2) {
        tip = "您确定要隐藏推广标签吗？";
        title = "隐藏";
      } else {
        tip = "您确定要显示推广标签吗？";
        title = "显示";
      }
      this.$confirm(tip, title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _xcx.setCategoryStatus)({
          id: id,
          status: status == 2 ? 1 : 2
        }).then(function (res) {
          _this2.getList(true);
        });
      }).catch(function () {});
    },
    // 更改排序
    changeSort: function changeSort(id, val) {
      var _this3 = this;
      (0, _xcx.setCategorySort)({
        id: id,
        sort: val
      }).then(function (res) {
        _this3.getList(true);
      });
    },
    getList: function getList(flag) {
      var _this4 = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      if (!flag) {
        this.tableData = [];
      }
      (0, _xcx.categoryList)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        _this4.tableData = res.data || [];
        _this4.total = res.total;
      });
    },
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    reset: function reset() {
      this.searchForm = {
        name: '',
        status: ''
      };
      this.$refs.customPage.page = 1;
      this.getList();
    },
    openAddDialog: function openAddDialog(val) {
      this.editItem = val || null;
      this.$refs.addDialog.dialogVisible = true;
    }
  }
};
exports.default = _default;