"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _branchBack = require("@/api/iot/branchBack");
var _status = require("@/api/iot/status");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _editDialog = _interopRequireDefault(require("./dialog/editDialog"));
var _benefitDialog = _interopRequireDefault(require("./dialog/benefitDialog"));
var _merchantSelect = _interopRequireDefault(require("@/components/merchant/merchantSelect.vue"));
var _default = {
  components: {
    customPage: _customPage.default,
    editDialog: _editDialog.default,
    benefitDialog: _benefitDialog.default,
    MerchantSelector: _merchantSelect.default
  },
  data: function data() {
    return {
      loading: true,
      searchForm: {
        merchant_id: '',
        status: ''
      },
      total: 0,
      tableData: [],
      branchStatusList: [],
      editItem: null
    };
  },
  mounted: function mounted() {
    this.getBranchStatusList();
    this.getList();
  },
  methods: {
    handleMerchantIdUpdate: function handleMerchantIdUpdate(merchantId) {
      this.searchForm.merchant_id = merchantId;
      console.log('this.searchForm', this.searchForm);
    },
    getBranchStatusList: function getBranchStatusList() {
      var _this = this;
      (0, _status.getBranchStatusList)().then(function (res) {
        console.log('getBranchStatusList', res);
        if (res) {
          _this.branchStatusList = res || [];
        }
      });
    },
    getList: function getList(flag) {
      var _this2 = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      if (!flag) {
        this.tableData = [];
      }
      this.loading = true;
      (0, _branchBack.branchGetList)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        _this2.loading = false;
        _this2.tableData = res.data || [];
        _this2.total = res.total;
      });
    },
    // 编辑
    openEditDialog: function openEditDialog(val) {
      this.editItem = val || null;
      this.$refs.editDialog.dialogVisible = true;
    },
    // 分润
    openBenefitDialog: function openBenefitDialog(val) {
      this.editItem = val || null;
      this.$refs.benefitDialog.dialogVisible = true;
    },
    // 删除
    removeItem: function removeItem(item) {
      var _this3 = this;
      this.$confirm('您确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _branchBack.branchDel)({
          id: item.id
        }).then(function (res) {
          _this3.getList(true);
        });
      }).catch(function () {});
    },
    // 批量操作
    handleCommand: function handleCommand(command) {},
    // 查询
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      this.searchForm = this.$options.data().searchForm;
      this.getList();
    }
  }
};
exports.default = _default;