"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
var _finance = require("@/api/finance");
var _deductLog = _interopRequireDefault(require("./deductLog"));
var _default = {
  props: ["orderId"],
  components: {
    deductLog: _deductLog.default
  },
  data: function data() {
    return {
      loading: true,
      dialogVisible: false,
      tableData: [],
      selectedItem: {}
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.loading = true;
        this.getOrderRiskControl();
      }
    }
  },
  methods: {
    getOrderRiskControl: function getOrderRiskControl() {
      var _this = this;
      (0, _order.getOrderRiskControl)(this.orderId).then(function (res) {
        _this.tableData = res || [];
        _this.loading = false;
      });
    }
  }
};
exports.default = _default;