"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _order = require("@/api/order");
var _default = {
  props: ['editItem'],
  data: function data() {
    return {
      dialogVisible: false,
      form: {
        orderId: "",
        relet_goods_id: 0
      },
      rules: {
        relet_goods_id: [{
          required: true,
          message: '商品id不能为空',
          trigger: 'blur'
        }]
      }
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        console.log('this.editItem', (0, _objectSpread2.default)({}, this.editItem));
        this.form.orderId = this.editItem.id;
        this.form.relet_goods_id = this.editItem.relet_goods_id;
      } else {
        this.form = this.$options.data().form;
        this.$refs['ruleForm'].resetFields();
      }
    }
  },
  methods: {
    submit: function submit() {
      var _this = this;
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          (0, _order.setReletId)((0, _objectSpread2.default)({}, _this.form)).then(function (res) {
            _this.dialogVisible = false;
            _this.$emit('getList', true);
          });
        } else {
          _this.$message({
            message: "请检查表单错误",
            type: "error"
          });
          return false;
        }
      });
    }
  }
};
exports.default = _default;