"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _base = _interopRequireDefault(require("./child/base"));
var _main = _interopRequireDefault(require("./child/main"));
var _code = _interopRequireDefault(require("./child/code"));
var _rate = _interopRequireDefault(require("./child/rate"));
var _config = _interopRequireDefault(require("./child/config"));
var _concern = _interopRequireDefault(require("./child/concern.vue"));
var _cache = _interopRequireDefault(require("./child/cache"));
var _web = require("@/api/web");
var _default = {
  components: {
    baseInfo: _base.default,
    mainInfo: _main.default,
    codeInfo: _code.default,
    rateInfo: _rate.default,
    cacheInfo: _cache.default,
    configInfo: _config.default,
    concernInfo: _concern.default
  },
  data: function data() {
    return {
      activeName: 'base'
    };
  },
  methods: {
    handleClick: function handleClick(tab) {
      this.activeName = tab.name;
    },
    delAllRedis: function delAllRedis() {
      var _this = this;
      this.$confirm('清除所有缓存', '请确认是否删除，会导致第一次请求变慢', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _web.delAllRedis)(id).then(function (res) {
          _this.getList();
        });
      }).catch(function () {});
    }
  }
};
exports.default = _default;