"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _online = _interopRequireDefault(require("./child/online"));
var _default = {
  components: {
    online: _online.default
  },
  data: function data() {
    return {
      activeName: '1',
      count: {
        draft: 0,
        status_put: 0,
        status_off: 0,
        status_check: 0
      }
    };
  },
  methods: {
    handleClick: function handleClick(tab) {
      this.activeName = tab.name;
    }
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    next(function (vm) {
      vm.$refs.listPage.getList();
    });
  }
};
exports.default = _default;