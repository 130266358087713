import { render, staticRenderFns } from "./branch.vue?vue&type=template&id=6dd7568a&"
import script from "./branch.vue?vue&type=script&lang=js&"
export * from "./branch.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\ssz\\xxz2\\battery_iot\\iot_vehicle\\iot_frontend\\iot_supplier\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6dd7568a')) {
      api.createRecord('6dd7568a', component.options)
    } else {
      api.reload('6dd7568a', component.options)
    }
    module.hot.accept("./branch.vue?vue&type=template&id=6dd7568a&", function () {
      api.rerender('6dd7568a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/chkg/branch/branch.vue"
export default component.exports