"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _addCategory = _interopRequireDefault(require("../addCategory"));
var _shop = require("@/api/shop");
var _default = {
  components: {
    customPage: _customPage.default,
    addCategory: _addCategory.default
  },
  data: function data() {
    return {
      tableData: [],
      total: 0
    };
  },
  methods: {
    goToManage: function goToManage(id, name) {
      this.$router.push({
        name: 'shopManage',
        params: {
          editId: id,
          editName: name
        }
      });
    },
    // 更改排序
    changeSort: function changeSort(id, val) {
      var _this = this;
      (0, _shop.editSort)({
        type: 'ShopCategory',
        id: id,
        sort: val
      }).then(function (res) {
        _this.getList();
      });
    },
    openAddDialog: function openAddDialog() {
      this.$refs.addCategory.dialogVisible = true;
    },
    // 删除
    removeItem: function removeItem(id, num) {
      var _this2 = this;
      var tip = "所删分类包含商品数：" + num + "，确认删除该分类吗？";
      this.$confirm(tip, '删除分类', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _shop.delShopCategory)(id).then(function (res) {
          _this2.getList();
        });
      }).catch(function () {});
    },
    getList: function getList() {
      var _this3 = this;
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      (0, _shop.shopCategoryList)({
        page: page,
        pageSize: pageSize
      }).then(function (res) {
        _this3.tableData = res.data || [];
        _this3.total = res.total;
      });
    }
  },
  mounted: function mounted() {
    this.getList();
  }
};
exports.default = _default;