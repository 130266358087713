"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));
var _merchant = require("@/api/merchant");
var _default = {
  components: {
    customPage: _customPage.default,
    myDatePicker: _myDatePicker.default
  },
  data: function data() {
    return {
      loading: false,
      searchForm: {
        trade_no: '',
        payeeAccount: '',
        status: '',
        create_time: ''
      },
      tableData: [],
      total: 0
    };
  },
  methods: {
    viewItem: function viewItem(id) {
      this.$router.push({
        name: 'merchantMyWithdraw',
        params: {
          withdrawId: id
        }
      });
    },
    // 获取数据
    getList: function getList() {
      var _this = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      this.loading = true;
      (0, _merchant.withdrawalList)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        _this.loading = false;
        _this.tableData = res.data || [];
        _this.total = res.total || 0;
      });
    },
    // 查询
    search: function search() {
      var pickerVal = this.$refs.myDatePicker.getVal();
      if (pickerVal !== 'error') {
        this.searchForm.create_time = pickerVal;
        this.$refs.customPage.page = 1;
        this.getList();
      }
    },
    // 重置
    reset: function reset() {
      if (this.$refs.myDatePicker) {
        this.$refs.myDatePicker.reset();
      }
      this.searchForm = this.$options.data().searchForm;
      this.getList();
    }
  },
  mounted: function mounted() {
    this.getList();
  }
};
exports.default = _default;