import { render, staticRenderFns } from "./mayiplatadd.vue?vue&type=template&id=7e212d46&"
import script from "./mayiplatadd.vue?vue&type=script&lang=js&"
export * from "./mayiplatadd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\ssz\\xxz2\\battery_iot\\iot_vehicle\\iot_frontend\\iot_supplier\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7e212d46')) {
      api.createRecord('7e212d46', component.options)
    } else {
      api.reload('7e212d46', component.options)
    }
    module.hot.accept("./mayiplatadd.vue?vue&type=template&id=7e212d46&", function () {
      api.rerender('7e212d46', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/system/merchant/mayiplatadd.vue"
export default component.exports