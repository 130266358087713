"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _shop = require("@/api/shop");
var _default = {
  components: {
    customPage: _customPage.default
  },
  data: function data() {
    return {
      tableData: [],
      total: 0
    };
  },
  methods: {
    changeStatus: function changeStatus(id, status) {
      var _this = this;
      if (!this.$hasMethod("#enable")) {
        return;
      }
      var tip = "",
        title = "";
      if (status == 2) {
        tip = "您确定要禁用吗？";
        title = "禁用";
      } else {
        tip = "您确定要启用吗？";
        title = "启用";
      }
      this.$confirm(tip, title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _shop.addOrEditReturnAddress)({
          id: id,
          status: status == 2 ? 1 : 2
        }).then(function (res) {
          _this.getList();
        });
      }).catch(function () {});
    },
    setItem: function setItem(id) {
      var _this2 = this;
      (0, _shop.addOrEditReturnAddress)({
        id: id,
        isdefault: 1
      }).then(function (res) {
        _this2.getList();
      });
    },
    deleteItem: function deleteItem(id) {
      var _this3 = this;
      this.$confirm('您确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _shop.delReturnAddress)(id).then(function (res) {
          _this3.getList();
        });
      }).catch(function () {});
    },
    editItem: function editItem(id) {
      this.$router.push({
        name: 'shopAddReturnAd',
        params: {
          editId: id
        }
      });
    },
    getList: function getList() {
      var _this4 = this;
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      (0, _shop.returnAddressList)({
        page: page,
        pageSize: pageSize
      }).then(function (res) {
        _this4.tableData = res.data || [];
        _this4.total = res.total || 0;
      });
    }
  },
  mounted: function mounted() {
    this.getList();
  }
};
exports.default = _default;