import { render, staticRenderFns } from "./orderShareList.vue?vue&type=template&id=90b4aa64&scoped=true&"
import script from "./orderShareList.vue?vue&type=script&lang=js&"
export * from "./orderShareList.vue?vue&type=script&lang=js&"
import style0 from "./orderShareList.vue?vue&type=style&index=0&id=90b4aa64&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90b4aa64",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\ssz\\xxz2\\battery_iot\\iot_vehicle\\iot_frontend\\iot_supplier\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('90b4aa64')) {
      api.createRecord('90b4aa64', component.options)
    } else {
      api.reload('90b4aa64', component.options)
    }
    module.hot.accept("./orderShareList.vue?vue&type=template&id=90b4aa64&scoped=true&", function () {
      api.rerender('90b4aa64', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/order/dialogs/orderShareList.vue"
export default component.exports