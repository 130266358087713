"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _customPage = _interopRequireDefault(require("@/components/customPage.vue"));
var _mockService = _interopRequireDefault(require("@/utils/mockService"));
var _default = {
  components: {
    customPage: _customPage.default
  },
  data: function data() {
    return {
      total: 0,
      mockTableData: [],
      tableData: []
    };
  },
  methods: {
    getMockData: function getMockData() {
      var _this = this;
      _mockService.default.get('/share/terminal/page?current=1&size=10&imei=1&state=1&shareMerchantId=2024012000002&serviceNetId=6bd0e261339f32c39bd6ceb5856d8f8d&deviceName=2').then(function (res) {
        console.log('resss', res.data);
        var _res$data$records = res.data.records,
          records = _res$data$records === void 0 ? [] : _res$data$records;
        _this.mockTableData = records;
        console.log('this.mockTableData', _this.mockTableData);
      });
    },
    // 获取数据
    getList: function getList(flag) {
      this.getMockData();
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      if (!flag) {
        this.tableData = [];
      }
      this.loading = true;
      // getMerchantAndShareMerchantListByUser({}).then((res) => {
      //     console.log('res', res)
      // })
    },
    headerCellStyle: function headerCellStyle() {
      return {
        fontSize: '13px',
        color: '#515a6e',
        fontWeight: 700 // 添加加粗样式
      };
    }
  }
};
exports.default = _default;