"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
var _vuex = require("vuex");
var _index = _interopRequireDefault(require("./index.js"));
// 尝试将用户在根目录中的store/index.js的vuex的state变量，全部加载到全局变量中
var stateKey = [];
var mutationsKey = [];
var actionsKey = [];
try {
  stateKey = _index.default.state ? Object.keys(_index.default.state) : [];
  mutationsKey = _index.default._mutations ? Object.keys(_index.default._mutations) : [];
  actionsKey = _index.default._actions ? Object.keys(_index.default._actions) : [];
} catch (e) {
  console.log('mixinStore-err', e);
}
var mixinStore = {
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)(stateKey)),
  methods: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapMutations)(mutationsKey)), (0, _vuex.mapActions)(actionsKey))
};
var _default = mixinStore;
exports.default = _default;