"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPayeeInfo = getPayeeInfo;
exports.myAccount = myAccount;
exports.recharge = recharge;
exports.rechargeQuery = rechargeQuery;
exports.withdrawalApply = withdrawalApply;
exports.withdrawalInfo = withdrawalInfo;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
// 我的账户
function myAccount() {
  return _fetch.default.post("/Supplier/Account/myAccount");
}
// 获取收款账户信息
function getPayeeInfo() {
  return _fetch.default.post("/Supplier/Account/getPayeeInfo");
}
// 申请提现
function withdrawalApply(amount) {
  return _fetch.default.post("/Supplier/Withdrawal/withdrawalApply", {
    amount: amount
  }, true);
}
// 账户充值
function recharge(amount) {
  return _fetch.default.post("/Supplier/Account/recharge", {
    amount: amount
  });
}
// 提现详情
function withdrawalInfo(withdrawal_id) {
  return _fetch.default.get("/Supplier/Withdrawal/withdrawalInfo", {
    withdrawal_id: withdrawal_id
  });
}
// 扫码结果查询
function rechargeQuery(rechargeId) {
  return _fetch.default.post("/Supplier/Account/rechargeQuery", {
    rechargeId: rechargeId
  });
}