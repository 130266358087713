"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _system = require("@/api/system");
var _xcx = require("@/api/xcx");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));
var _default = {
  components: {
    customPage: _customPage.default,
    myDatePicker: _myDatePicker.default
  },
  data: function data() {
    return {
      loading: false,
      appid: '',
      searchForm: {
        title: ''
      },
      total: 0,
      tableData: []
    };
  },
  mounted: function mounted() {
    this.getAlipayAppid();
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      var flag = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      if (!flag) {
        this.tableData = [];
      }
      this.loading = true;
      this.loading = true;
      (0, _xcx.getEventList)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        _this.loading = false;
        _this.tableData = res.data || [];
        _this.total = res.total;
        _this.loading = false;
      });
    },
    getAlipayAppid: function getAlipayAppid() {
      var _this2 = this;
      (0, _system.getAlipayAppid)().then(function (res) {
        if (res) {
          _this2.appid = res;
        }
      });
    },
    toEdit: function toEdit() {
      var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      this.$router.push({
        name: 'eventEdit',
        params: {
          id: id
        }
      });
    },
    // 发布/取消
    changeRelease: function changeRelease(item) {
      var _this3 = this;
      var status = item.status == 1 ? 2 : 1;
      (0, _xcx.releaseEventPage)(item.id, status).then(function (res) {
        _this3.getList(1);
      });
    },
    alipayExample: function alipayExample(id) {
      // alipays://platformapi/startapp?appId=2021003162624564&page=%2FnineClassify%2Fpages%2FafterPay%2FafterPay&query=channel%3DKEY

      var url = '/nineClassify/pages/event/event?id=' + id;
      var path = "alipays://platformapi/startapp?appId=".concat(this.appid, "&page=") + encodeURIComponent(url);
      return path;
    },
    // 查询
    search: function search() {
      this.getList();
    },
    // 重置
    reset: function reset() {
      this.searchForm = this.$options.data().searchForm;
      this.getList();
    }
  }
};
exports.default = _default;