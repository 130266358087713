"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _goodsDialog = _interopRequireDefault(require("@/components/goodsDialog"));
var _shop = require("@/api/shop");
var _default = {
  components: {
    customPage: _customPage.default,
    goodsDialog: _goodsDialog.default
  },
  props: ['editId', 'editName'],
  data: function data() {
    return {
      searchForm: {
        title: ''
      },
      tableData: [],
      total: 0
    };
  },
  methods: {
    // 设置选中的商品
    setSelect: function setSelect(goodsList) {
      var _this = this;
      var goodsidStr = goodsList.map(function (k) {
        return k.id;
      }).join(',');
      (0, _shop.addShopGoods)({
        cat_id: this.editId,
        goodsidStr: goodsidStr
      }).then(function (res) {
        _this.getList();
      });
    },
    // 更改排序
    changeSort: function changeSort(id, val) {
      var _this2 = this;
      (0, _shop.editSort)({
        type: 'ShopGoods',
        id: id,
        sort: val
      }).then(function (res) {
        _this2.getList();
      });
    },
    // 删除
    removeItem: function removeItem(id) {
      var _this3 = this;
      this.$confirm('确认删除该分类的商品吗？', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _shop.delShopGoods)(id).then(function (res) {
          _this3.getList();
        });
      }).catch(function () {});
    },
    openAdd: function openAdd() {
      // 打开商品选择对话框
      this.$refs.goodsDialog.dialogVisible = true;
    },
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    getList: function getList() {
      var _this4 = this;
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      (0, _shop.shopGoodsList)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize,
        id: this.editId
      }, this.searchForm)).then(function (res) {
        _this4.tableData = res.data || [];
        _this4.total = res.total;
      });
    }
  },
  mounted: function mounted() {
    this.$route.meta.title = '关联商品 > "' + this.editName + '"分类商品';
    this.getList();
  }
};
exports.default = _default;