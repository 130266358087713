"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _system = require("@/api/system");
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
var _default2 = {
  components: {
    cropUpload: _cropUpload.default
  },
  props: {
    editItem: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      formData: {
        id: 0,
        is_default: 0,
        sort: 0,
        status: 1,
        is_show_list: 1,
        is_show_popup: 1,
        tips: '',
        title: '',
        icon: '',
        key: ''
      },
      rules: {
        key: [{
          required: true,
          message: '请输入key',
          trigger: 'blur'
        }],
        title: [{
          required: true,
          message: '请输入支付名称',
          trigger: 'blur'
        }],
        tips: [{
          required: true,
          message: '请输入支付说明',
          trigger: 'blur'
        }]
        // icon: [
        //     { required: true, message: '请输上传图标', trigger: 'blur' },
        // ],
      }
    };
  },

  computed: {
    dialogTitle: function dialogTitle() {
      return '支付通道 - ' + (this.formData.id == 0 ? '新增' : '编辑');
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this = this;
      if (val) {
        this.$nextTick(function () {
          _this.formData = Object.assign(_this.formData, _this.editItem);
          console.log('this.editItem', (0, _objectSpread2.default)({}, _this.editItem));
        });
      } else {
        this.formData = this.$options.data().formData;
        this.$refs.formData.clearValidate();
      }
    }
  },
  methods: {
    open: function open() {
      this.dialogVisible = true;
    },
    close: function close() {
      this.dialogVisible = false;
    },
    submitForm: function submitForm() {
      var _this2 = this;
      console.log('submitForm');
      this.$refs['formData'].validate(function (valid) {
        if (valid) {
          (0, _system.editPayMent)({
            form_data: _this2.formData
          }).then(function (res) {
            _this2.$emit('getList', true);
            _this2.close();
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default2;