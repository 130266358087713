"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
var _default = {
  components: {
    cropUpload: _cropUpload.default
  },
  props: ['orderId'],
  data: function data() {
    return {
      dialogVisible: false,
      formData: {
        money: 0,
        payMoney: 0
      },
      rules: {
        evidence_url: [{
          required: true,
          message: '请上传图片',
          trigger: 'change'
        }]
      }
    };
  },
  mounted: function mounted() {},
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.setCanPayMoney();
      }
    }
  },
  methods: {
    setCanPayMoney: function setCanPayMoney() {
      var _this = this;
      (0, _order.getCanPayMoneyByOrderId)({
        order_id: this.orderId
      }).then(function (res) {
        _this.formData.money = parseFloat(res);
      }).catch(function () {});
    },
    submit: function submit() {
      var _this2 = this;
      // this.$confirm("您确定要申请扣款吗？", "申请扣款", {
      // 	confirmButtonText: '确定',
      // 	cancelButtonText: '取消'
      // }).then(() => {

      if (this.formData.payMoney <= 0) {
        this.$message.error("操作金额异常");
        return;
      }
      if (this.formData.payMoney > this.formData.money) {
        this.$message.error("操作金额过大");
        return;
      }
      var param = {
        order_id: this.orderId,
        pay_money: this.formData.payMoney
      };
      (0, _order.addOrderLeaseNoShow)(param).then(function (res) {
        _this2.$emit('success');
        _this2.close();
      }).catch(function () {});
    },
    close: function close() {
      this.dialogVisible = false;
    }
  }
};
exports.default = _default;