import { render, staticRenderFns } from "./draft.vue?vue&type=template&id=75d6c97e&scoped=true&"
import script from "./draft.vue?vue&type=script&lang=js&"
export * from "./draft.vue?vue&type=script&lang=js&"
import style0 from "./draft.vue?vue&type=style&index=0&id=75d6c97e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75d6c97e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\ssz\\xxz2\\battery_iot\\iot_vehicle\\iot_frontend\\iot_supplier\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('75d6c97e')) {
      api.createRecord('75d6c97e', component.options)
    } else {
      api.reload('75d6c97e', component.options)
    }
    module.hot.accept("./draft.vue?vue&type=template&id=75d6c97e&scoped=true&", function () {
      api.rerender('75d6c97e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/goods/draft.vue"
export default component.exports