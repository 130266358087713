"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deviceTypeList = deviceTypeList;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/**
 * 换电柜类型
 */
// 角色列表
function deviceTypeList(params) {
  return _fetch.default.get("/Supplier/deviceType/getList", params);
}