"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _marketing = require("@/api/marketing");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));
var _buy = _interopRequireDefault(require("./components/buy"));
var _recharge = _interopRequireDefault(require("./components/recharge"));
var _default = {
  components: {
    customPage: _customPage.default,
    myDatePicker: _myDatePicker.default,
    buyDialog: _buy.default,
    rechargeDialog: _recharge.default
  },
  data: function data() {
    return {
      tableData: [],
      total: 0,
      searchForm: {
        order_id: "",
        user_name: "",
        user_mobile: "",
        create_time: ""
      },
      risk_credit_automatic: 0,
      risk_credit_amount: 0,
      disabled: false
    };
  },
  methods: {
    checkUrl: function checkUrl(url) {
      if (url) {
        window.open(url);
      } else {
        this.$message.warning("暂无法查看报告");
        return;
      }
    },
    // 自动充值
    autoRecharge: function autoRecharge() {
      var _this = this;
      if (!this.showTip()) {
        return;
      }
      if (this.risk_credit_automatic == 1) {
        // 关闭
        this.$confirm("确认要关闭自动充值吗?", "关闭自动充值", {
          confirmButtonText: "确定",
          cancelButtonText: "取消"
        }).then(function () {
          (0, _marketing.riskCreditAutoRecharge)().then(function (res) {
            _this.getList();
          });
        }).catch(function () {});
      } else {
        // 开启
        this.$refs.rechargeDialog.dialogVisible = true;
      }
    },
    // 去购买
    toBuy: function toBuy() {
      if (!this.showTip()) {
        return;
      }
      this.$refs.buyDialog.dialogVisible = true;
    },
    // 查看合同购买记录
    checkRecords: function checkRecords() {
      if (!this.showTip()) {
        return;
      }
      this.$router.push({
        name: "serviceRiskRecord"
      });
    },
    // 查询
    search: function search() {
      if (!this.showTip()) {
        return;
      }
      var pickerVal = this.$refs.myDatePicker.getVal();
      if (pickerVal !== "error") {
        this.searchForm.create_time = pickerVal;
        this.$refs.customPage.page = 1;
        this.getList();
      }
    },
    // 重置
    reset: function reset() {
      if (!this.showTip()) {
        return;
      }
      if (this.$refs.myDatePicker) {
        this.$refs.myDatePicker.reset();
      }
      this.searchForm = {
        order_id: "",
        user_name: "",
        user_mobile: "",
        create_time: ""
      };
      this.getList();
    },
    // 获取数据
    getList: function getList() {
      var _this2 = this;
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      (0, _marketing.getShopRiskCreditList)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        var data = res.data,
          risk_credit_automatic = res.risk_credit_automatic,
          risk_credit_amount = res.risk_credit_amount;
        _this2.tableData = data.data || [];
        _this2.total = data.total || 0;
        _this2.risk_credit_automatic = risk_credit_automatic;
        _this2.risk_credit_amount = risk_credit_amount;
      });
    },
    goToOrder: function goToOrder(orderId) {
      if (orderId) {
        this.$router.push({
          name: "orderList",
          params: {
            orderId: orderId
          }
        });
      }
    },
    getData: function getData() {
      var _this3 = this;
      (0, _marketing.getShopRiskCreditStuatus)().then(function (res) {
        _this3.disabled = false;
        _this3.getList();
      }).catch(function (err) {
        //   this.disabled = true;
      });
    },
    showTip: function showTip() {
      if (this.disabled) {
        this.$message({
          message: "该店铺暂未开启百融风控, 请先开启后再查看该功能",
          type: "error"
        });
        return false;
      }
      return true;
    }
  },
  mounted: function mounted() {
    this.getData();
  }
};
exports.default = _default;