"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.batteryDel = batteryDel;
exports.batteryList = batteryList;
exports.batterySave = batterySave;
exports.getDetails = getDetails;
exports.getHistoryMByMUtc = getHistoryMByMUtc;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/**
 * 换电柜
 */
// 换电柜列表
function batteryList(params) {
  return _fetch.default.get("/Supplier/battery/getList", params);
}

//换电柜修改
function batterySave(params) {
  return _fetch.default.post("/Supplier/battery/save", params, true);
}
//删除电柜修改
function batteryDel(params) {
  return _fetch.default.post("/Supplier/battery/del", params, true);
}
// 获取电池轨迹
function getHistoryMByMUtc(params) {
  return _fetch.default.get("/Supplier/battery/getHistoryMByMUtc", params);
}
// 获取电池详情
function getDetails(params) {
  return _fetch.default.get("/Supplier/battery/getDetails", params);
}