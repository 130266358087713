"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.appAlipayLogin = appAlipayLogin;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/**
 *  app登录
 */
// app登录授权
function appAlipayLogin(params) {
  return _fetch.default.post("/Supplier/appAlipayLogin", params);
}