"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vuex = _interopRequireDefault(require("vuex"));
var _getters = _interopRequireDefault(require("./getters"));
var _user = _interopRequireDefault(require("./modules/user"));
var _permission = _interopRequireDefault(require("./modules/permission"));
var _auth = require("@/utils/auth");
_vue.default.use(_vuex.default);
var store = new _vuex.default.Store({
  modules: {
    user: _user.default,
    permission: _permission.default
  },
  getters: _getters.default,
  state: function state() {
    return {
      sMerchantId: (0, _auth.getMerchantId)() || 0,
      triggerApiCall: false
    };
  },
  mutations: {
    sSetMerchantId: function sSetMerchantId(state, data) {
      state.sMerchantId = data || 0;
    },
    setTriggerApiCall: function setTriggerApiCall(state, data) {
      state.triggerApiCall = data;
    }
  },
  actions: {}
});
var _default = store;
exports.default = _default;