"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find");
var _global = _interopRequireDefault(require("../../utils/global"));
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));
var _finance = require("@/api/finance");
var _default = {
  components: {
    customPage: _customPage.default,
    myDatePicker: _myDatePicker.default
  },
  data: function data() {
    return {
      searchForm: {
        orderNo: '',
        orderStatus: '',
        goodsName: '',
        receivingName: '',
        leaseTerm: '',
        orderCreateTime: '',
        orderPayTime: ''
      },
      statusList: _global.default.orderStatus,
      selectList: [],
      tableData: [],
      total: 0
    };
  },
  methods: {
    goToOrder: function goToOrder(orderId) {
      if (orderId) {
        this.$router.push({
          name: 'orderList',
          params: {
            orderId: orderId
          }
        });
      }
    },
    getOrderStatusName: function getOrderStatusName(status) {
      var obj = this.statusList.find(function (item) {
        return item.id == status;
      });
      return obj ? obj.name : '';
    },
    getOrderStatusType: function getOrderStatusType(status) {
      var obj = this.statusList.find(function (item) {
        return item.id == status;
      });
      return obj ? obj.type : 'warning';
    },
    // 获取数据
    getList: function getList(flag) {
      var _this = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      if (!flag) {
        this.tableData = [];
      }
      (0, _finance.financeHuaBeiList)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        _this.tableData = res.data || [];
        _this.total = res.total || 0;
      });
    },
    // 查询
    search: function search() {
      var pickerVal1 = this.$refs.makeOrderPicker.getVal();
      var pickerVal3 = this.$refs.payPicker.getVal();
      if (pickerVal1 == 'error' || pickerVal3 == 'error') {
        return;
      }
      this.searchForm.orderCreateTime = pickerVal1;
      this.searchForm.orderPayTime = pickerVal3;
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      if (this.$refs.makeOrderPicker) {
        this.$refs.makeOrderPicker.reset();
      }
      if (this.$refs.payPicker) {
        this.$refs.payPicker.reset();
      }
      this.searchForm = {
        orderNo: '',
        orderStatus: '',
        goodsName: '',
        receivingName: '',
        leaseTerm: '',
        orderCreateTime: '',
        orderPayTime: ''
      };
      this.getList();
    },
    getSelect: function getSelect() {
      var _this2 = this;
      (0, _finance.getOrderStatus)().then(function (res) {
        var list = [];
        for (var key in res) {
          list.push({
            id: key,
            name: res[key]
          });
        }
        _this2.selectList = list || [];
      });
    },
    exportFile: function exportFile() {
      (0, _finance.huaBeiListExport)(this.searchForm);
    }
  },
  mounted: function mounted() {
    this.getSelect();
    this.getList();
  }
};
exports.default = _default;