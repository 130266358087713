"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-dialog", {
    staticClass: "add_dialog",
    attrs: {
      width: "600px",
      title: _vm.dialogTitle,
      visible: _vm.dialogVisible,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      rules: _vm.rules,
      model: _vm.form,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所在网点",
      prop: "branch_id"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.form.branch_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "branch_id", $$v);
      },
      expression: "form.branch_id"
    }
  }, _vm._l(_vm.branchList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "换电柜编号",
      prop: "terminal_no"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.form.terminal_no,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "terminal_no", $$v);
      },
      expression: "form.terminal_no"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "换电柜名称",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入换电柜名称"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), !_vm.editItem ? _c("el-form-item", {
    attrs: {
      label: "电池仓位数",
      prop: "warehouse_num"
    }
  }, [_c("el-input-number", {
    attrs: {
      disabled: _vm.editItem != null
    },
    model: {
      value: _vm.form.warehouse_num,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "warehouse_num", $$v);
      },
      expression: "form.warehouse_num"
    }
  })], 1) : _vm._e(), _c("el-form-item", {
    attrs: {
      label: "运营状态",
      prop: "status"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "status", $$v);
      },
      expression: "form.status"
    }
  }, _vm._l(_vm.deviceStatusList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.label,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "在线状态",
      prop: "status"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.form.is_online,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "is_online", $$v);
      },
      expression: "form.is_online"
    }
  }, _vm._l(_vm.onlineStatusList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.label,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "换电柜类型",
      prop: "device_type_id"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.form.device_type_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "device_type_id", $$v);
      },
      expression: "form.device_type_id"
    }
  }, _vm._l(_vm.deviceTypeList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary",
      loading: _vm.btnLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("保 存")])], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;