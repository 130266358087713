"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
var _default = {
  components: {
    cropUpload: _cropUpload.default
  },
  data: function data() {
    return {
      imgList: [{
        img: '',
        jumpUrl: ''
      }, {
        img: '',
        jumpUrl: ''
      }]
    };
  }
};
exports.default = _default;