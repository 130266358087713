"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _orderRefund = require("@/api/orderRefund");
var _checkDetails = _interopRequireDefault(require("./dialog/checkDetails.vue"));
var _agreeRefund = _interopRequireDefault(require("./dialog/agreeRefund.vue"));
var _refuseRefund = _interopRequireDefault(require("./dialog/refuseRefund.vue"));
var _default = {
  props: ["id"],
  components: {
    checkDetails: _checkDetails.default,
    agreeRefund: _agreeRefund.default,
    refuseRefund: _refuseRefund.default
  },
  data: function data() {
    return {
      detailInfo: {},
      tableData: [],
      recordData: [],
      imgList: [],
      currentId: "",
      orderId: "",
      refundStatus: ""
    };
  },
  methods: {
    getList: function getList() {
      var _this = this;
      (0, _orderRefund.orderRefundInfo)(this.id).then(function (res) {
        _this.detailInfo = res;
        _this.recordData = res.order_log;
        _this.tableData = [{}];
        for (var i in res) {
          _this.$set(_this.tableData[0], i, res[i]);
        }
        _this.imgList = res.refund_image ? res.refund_image.split(',') : [];
      });
    },
    toClose: function toClose() {
      this.$router.go(-1);
    },
    toDetails: function toDetails(id) {
      this.currentId = id;
      this.$refs.checkDetails.dialogVisible = true;
    },
    agreeRefunds: function agreeRefunds(row) {
      this.currentId = row.id;
      this.orderId = row.order_id;
      this.refundStatus = row.refund_status;
      this.$refs.agreeRefund.dialogVisible = true;
    },
    refuseRefunds: function refuseRefunds(row) {
      this.currentId = row.id;
      this.refundStatus = row.refund_status;
      this.$refs.refuseRefund.dialogVisible = true;
    }
  },
  mounted: function mounted() {
    this.getList();
  }
};
exports.default = _default;