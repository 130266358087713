"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/toConsumableArray.js"));
var _objectSpread2 = _interopRequireDefault(require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
var _nineGrid = _interopRequireDefault(require("./nineGrid.vue"));
var _goodsAdd = _interopRequireDefault(require("./goodsAdd"));
var _default = {
  components: {
    goodsAdd: _goodsAdd.default,
    cropUpload: _cropUpload.default,
    nineGrid: _nineGrid.default
  },
  props: ['goodsNum', 'levelName', 'compFormData', 'imgWidth', 'imgHeight'],
  data: function data() {
    return {
      loading: true,
      formData: {
        main_title: '',
        background_image: '',
        icon_img: '',
        background_color: '',
        btn_bg_color: '',
        exam_list: [],
        is_show: 0,
        is_control: false,
        is_nineGrid: false,
        goods: []
      },
      photoWidth: 359
    };
  },
  mounted: function mounted() {
    this.loading = true;
  },
  computed: {
    photoHeight: function photoHeight() {
      var height = 359 * this.imgHeight / this.imgWidth;
      return height;
    }
  },
  watch: {
    compFormData: {
      immediate: true,
      handler: function handler(val) {
        var _this = this;
        this.loading = true;
        this.formData = (0, _objectSpread2.default)({}, val);
        console.log('收到的参数', val);
        try {
          if (this.levelName == 'first_level') {
            if (this.formData.is_icon_conf) {
              this.formData.icon_img = this.formData.icon_img ? this.formData.icon_img : '';
            }
            this.formData.background_color = this.formData.background_color ? this.formData.background_color : '';
            this.formData.btn_bg_color = this.formData.btn_bg_color ? this.formData.btn_bg_color : '';
            this.formData.is_show = this.formData.is_show;
            this.formData.is_control = this.formData.is_control ? this.formData.is_control : false;
          }
          this.formData.background_image = this.formData.background_image ? this.formData.background_image : '';
          //
          if (this.levelName == 'fourth_level' && this.formData.is_nineGrid) {
            console.log('this.formData', this.formData);
            this.formData.is_nineGrid = this.formData.is_nineGrid ? this.formData.is_nineGrid : false;

            // 获取案例展示列表参数
            this.$nextTick(function () {
              _this.$refs.nineGrid.iconList = _this.formData.exam_list;
              console.log('sbsbsb', _this.formData.exam_list);
            });
          }
        } catch (error) {
          this.loading = false;
        } finally {
          this.loading = false;
        }
      }
    }
  },
  methods: {
    changeIsShow: function changeIsShow(e) {
      console.log('e', e);
      this.formData.is_show = e;
    },
    setGoods: function setGoods(list) {
      this.formData.goods = list;
    },
    validateIcon: function validateIcon(list) {
      for (var index in list) {
        var num = parseInt(index) + 1;
        if (!list[index].img) {
          this.$message({
            message: "\u56FE\u6807".concat(num, "\u7684\u56FE\u7247\u4E0D\u80FD\u4E3A\u7A7A"),
            type: 'error'
          });
          return false;
        }
        if (!list[index].title) {
          this.$message({
            message: "\u56FE\u6807".concat(num, "\u7684\u540D\u79F0\u4E0D\u80FD\u4E3A\u7A7A"),
            type: 'error'
          });
          return false;
        }
        // if (!list[index].jumpUrl) {
        //     this.$message({
        //         message: `图标${num}的链接不能为空`,
        //         type: 'error',
        //     })
        //     return false
        // }
      }

      return true;
    },
    // 保存九宫格
    saveNineGrid: function saveNineGrid() {
      var _this2 = this;
      var list = (0, _toConsumableArray2.default)(this.$refs.nineGrid.iconList);
      if (this.validateIcon(list)) {
        console.log('校验成功');
        this.formData.exam_list = list;
        return true;
        saveParams({
          type: 2,
          status: this.sectionList[this.activeNum - 1].show ? 2 : 1,
          params: JSON.stringify(list)
        }).then(function (res) {
          _this2.getParams(2);
        });
      }
      return false;
    },
    getSubmitData: function getSubmitData() {
      // let goodsidStr = undefined
      if (this.levelName == 'fourth_level' && this.formData.is_nineGrid) {
        if (!this.saveNineGrid()) return false;
      }
      console.log('this.formData.main_title', this.formData.main_title);
      if (!this.formData.is_control && this.levelName == 'first_level' && !this.formData.main_title) {
        this.$message({
          message: '主标题不能为空',
          type: 'error'
        });
        return false;
      }
      if (this.formData.is_control && this.levelName == 'first_level' && !this.formData.icon_img) {
        this.$message({
          message: '显示图标不能为空',
          type: 'error'
        });
        return false;
      }
      // goodsidStr += this.formData.goods.map((k) => k.goods_id).join(',')
      return {
        level: this.levelName,
        background_image: this.formData.background_image ? this.formData.background_image : '',
        main_title: this.formData.main_title ? this.formData.main_title : '',
        background_color: this.formData.background_color ? this.formData.background_color : '',
        btn_bg_color: this.formData.btn_bg_color ? this.formData.btn_bg_color : '',
        exam_list: this.formData.exam_list ? this.formData.exam_list : undefined,
        is_show: this.formData.is_show,
        icon_img: this.formData.icon_img ? this.formData.icon_img : ''
        // goodsidStr,
      };
    }
  }
};
exports.default = _default;