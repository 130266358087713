"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.delCoupon = delCoupon;
exports.delGift = delGift;
exports.delSendCoupon = delSendCoupon;
exports.delSetUserMerchantLimit = delSetUserMerchantLimit;
exports.delSetmeal = delSetmeal;
exports.editCoupon = editCoupon;
exports.editGift = editGift;
exports.getCouponList = getCouponList;
exports.getGiftList = getGiftList;
exports.getRentLogList = getRentLogList;
exports.getSendCouponList = getSendCouponList;
exports.getSetUserMerchantList = getSetUserMerchantList;
exports.saveSetUserMerchant = saveSetUserMerchant;
exports.sendCoupon = sendCoupon;
exports.setmealGetList = setmealGetList;
exports.setmealSave = setmealSave;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/**
 * 换电套餐管理
 */

// 换电套餐列表
function setmealGetList(params) {
  return _fetch.default.get('/Supplier/setmeal/getList', params);
}
// 换电套餐修改或添加
function setmealSave(params) {
  return _fetch.default.post('/Supplier/setmeal/save', params, true);
}

// 删除套餐
function delSetmeal(params) {
  return _fetch.default.post('/Supplier/setmeal/del', params, true);
}

// 礼包列表
function getGiftList(params) {
  return _fetch.default.get('/Supplier/setting/getGiftList', params);
}
// 礼包修改或添加
function editGift(params) {
  return _fetch.default.post('/Supplier/setting/editGift', params, true);
}

// 删除礼包
function delGift(params) {
  return _fetch.default.post('/Supplier/setting/delGift', params, true);
}

// 优惠券列表
function getCouponList(params) {
  return _fetch.default.get('/Supplier/setting/getCouponList', params);
}
// 优惠券修改或添加
function editCoupon(params) {
  return _fetch.default.post('/Supplier/setting/editCoupon', params, true);
}
// 删除优惠券
function delCoupon(params) {
  return _fetch.default.post('/Supplier/setting/delCoupon', params, true);
}
// 发送优惠券
function sendCoupon(params) {
  return _fetch.default.post('/Supplier/setting/sendCoupon', params, true);
}
// 已发送优惠券列表
function getSendCouponList(params) {
  return _fetch.default.get('/Supplier/setting/getSendCouponList', params);
}

// 删除已发送的优惠券
function delSendCoupon(params) {
  return _fetch.default.post('/Supplier/setting/delSendCoupon', params, true);
}
// 获取租借修改记录列表
function getRentLogList(params) {
  return _fetch.default.get('/Supplier/setting/getRentLogList', params);
}
// 获取修改的用户信息
function getSetUserMerchantList(params) {
  return _fetch.default.get('/Supplier/memMerIdLog/getMember', params);
}
// 保存修改的用户信息
function saveSetUserMerchant(params) {
  return _fetch.default.post('/Supplier/memMerIdLog/save', params, true);
}
// 去除地域限制
function delSetUserMerchantLimit(params) {
  return _fetch.default.post('/Supplier/memMerIdLog/del', params, true);
}