"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _merchant = require("@/api/merchant");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _add = _interopRequireDefault(require("./add"));
var _vuex = require("vuex");
var _default = {
  components: {
    customPage: _customPage.default,
    addDialog: _add.default
  },
  data: function data() {
    return {
      loading: false,
      searchForm: {
        title: '',
        status: ''
      },
      total: 0,
      tableData: [],
      editItem: null,
      menuData: []
    };
  },
  methods: {
    // 删除
    removeItem: function removeItem(id) {
      var _this = this;
      this.$confirm('您确定要删除角色吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        delGroup(id).then(function (res) {
          _this.getList(true);
        });
      }).catch(function () {});
    },
    // 添加/编辑账号
    openAddDialog: function openAddDialog(val) {
      this.editItem = val || null;
      this.$refs.addDialog.dialogVisible = true;
    },
    // 查询
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      this.searchForm = this.$options.data().searchForm;
      this.getList();
    },
    // 启用/禁用
    changeStatus: function changeStatus(id, status) {
      var _this2 = this;
      var tip = '';
      if (status == 1) {
        tip = "<span class='red-font'>当前角色有账号关联，禁用会造成账号失去权限</span><br><span>您确定要禁用角色吗？</span>";
      } else {
        tip = '<span>您确定要启用角色吗？</span>';
      }
      this.$confirm(tip, status == 1 ? '禁用角色' : '启用角色', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      }).then(function () {
        setGroup({
          id: id,
          status: status == 1 ? 0 : 1
        }).then(function (res) {
          _this2.getList(true);
        });
      }).catch(function () {});
    },
    getList: function getList(flag) {
      var _this3 = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      if (!flag) {
        this.loading = true;
        this.tableData = [];
      }
      console.log('this.searchForm', this.searchForm);
      (0, _merchant.getMerchantGroupList)({
        page: page,
        pageSize: pageSize,
        status: this.searchForm.status,
        title: this.searchForm.title
      }).then(function (res) {
        _this3.loading = false;
        _this3.tableData = res.data || [];
        _this3.total = res.total;
      });
    }
  },
  mounted: function mounted() {
    this.getList();
    this.menuData = this.rule || [];
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['rule']))
};
exports.default = _default;