"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find-index");
var _objectSpread2 = _interopRequireDefault(require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _Map = _interopRequireDefault(require("vue-baidu-map/components/map/Map.vue"));
var _MapType = _interopRequireDefault(require("vue-baidu-map/components/controls/MapType.vue"));
var _Marker = _interopRequireDefault(require("vue-baidu-map/components/overlays/Marker.vue"));
var _LocalSearch = _interopRequireDefault(require("vue-baidu-map/components/search/LocalSearch.vue"));
var _Polyline = _interopRequireDefault(require("vue-baidu-map/components/overlays/Polyline.vue"));
var _user = require("@/api/user");
var _default2 = {
  props: {
    mapId: {
      default: 'map'
    },
    // 地图初始中心点
    initCenter: {
      default: null
    },
    // 地图初始缩放等级
    initZoom: {
      default: ''
    },
    // 地图显示类型: markCenter-标记中心点位, pathLine-显示路线轨迹
    showType: {
      default: 'markCenter'
    },
    pathLine: {
      default: function _default() {
        return [];
      }
    }
  },
  components: {
    BaiduMap: _Map.default,
    MapType: _MapType.default,
    MapMarker: _Marker.default,
    BmLocalSearch: _LocalSearch.default,
    BmPolyline: _Polyline.default
  },
  data: function data() {
    return {
      loading: false,
      mapAK: '',
      showSearch: false,
      showCenter: false,
      mapCenter: {
        // 地图中心点位置，可以根据需要设置为输入的详细地址对应的经纬度
        lng: 0,
        lat: 0
      },
      mapZoom: 17,
      // 地图缩放级别
      markerPosition: {
        // 标记点位置，可以根据需要设置为输入的详细地址对应的经纬度
        lng: 0,
        lat: 0
      },
      imgEndSrc: require('@/assets/images/map/map-filling.png'),
      selectLoading: false,
      searchAddress: '',
      // 地址搜索
      addressUid: '',
      selectAddressList: [],
      BMap: null,
      map: null,
      geocoder: null,
      // 返回的地址信息
      sendAddressInfo: {
        point: {
          lng: 0,
          lat: 0
        },
        address: ''
      },
      startPoint: null,
      endPoint: null,
      startPointIcon: require('@/assets/images/map/map-path-line-1.png'),
      endPointIcon: require('@/assets/images/map/map-path-line-2.png')
    };
  },
  watch: {
    pathLine: function pathLine(val) {
      console.log('pathLine', val);
      this.setPathLine(val);
    }
  },
  mounted: function mounted() {
    console.log('map-mounted');
    this.init();
  },
  methods: {
    init: function init() {
      this.getMapAK();
    },
    getMapAK: function getMapAK() {
      var _this = this;
      this.mapAK = this.$func.getCookie('mapAK');
      if (!this.mapAK) {
        this.loading = true;
        (0, _user.getConfig)({
          path: 'global.baidumap'
        }).then(function (res) {
          // console.log('getConfig about supplier', res)
          _this.loading = false;
          if (res) {
            _this.mapAK = res;
            _this.$func.setCookie('mapAK', res);
          }
        });
      }
    },
    //地图加载后的回调
    mapReady: function mapReady(_ref) {
      var BMap = _ref.BMap,
        map = _ref.map;
      this.BMap = BMap;
      this.map = map;
      switch (this.showType) {
        case 'markCenter':
          this.initMarkCenter();
          break;
        case 'pathLine':
          this.initPathLine();
          break;
      }
      if (this.initZoom) {
        this.mapZoom = this.initZoom;
      }
      // 获取自动定位方法
      this.geocoder = new BMap.Geocoder();
    },
    // 初始化标记中心点位
    initMarkCenter: function initMarkCenter() {
      var _this2 = this;
      this.addEvent();
      this.showCenter = true;
      this.showSearch = true;
      setTimeout(function () {
        if (_this2.initCenter) {
          _this2.setCenterPos(_this2.initCenter);
        } else {
          _this2.getLocalCity();
        }
      }, 100);
    },
    // 初始路线模式
    initPathLine: function initPathLine() {},
    // 设置显示路线轨迹
    setPathLine: function setPathLine(pathLine) {
      // this.setCenterPos(pathLine[0])
      // console.log('setPathLine', pathLine)
      if (pathLine && pathLine.length > 0) {
        this.setCenterPos(pathLine[0]);
        this.startPoint = (0, _objectSpread2.default)({}, pathLine[0]);
        this.endPoint = (0, _objectSpread2.default)({}, pathLine[pathLine.length - 1]);
        // this.mapCenter = { lat: '25.061504', lng: '117.028928' }
      } else {
        this.getLocalCity();
      }
    },
    // 监听事件
    addEvent: function addEvent() {
      var _this3 = this;
      // 监听地图移动事件
      this.map.addEventListener('moving', function (e) {
        // console.log('moving', e)
        _this3.getMapCenter();
      });
      // 监听地图缩放事件
      this.map.addEventListener('zoomend', function (e) {
        // console.log('zoomend', e)
        _this3.getMapCenter();
      });
    },
    // 定位当前城市
    getLocalCity: function getLocalCity() {
      var _this4 = this;
      var localcity = new this.BMap.LocalCity();
      localcity.get(function (e) {
        // console.log('localcity', e)
        if (e.center) {
          _this4.setCenterPos(e.center);
        }
      });
    },
    // 移动地图中心点
    setCenterPos: function setCenterPos(point) {
      // console.log('setCenterPos', { ...point })
      this.mapCenter = (0, _objectSpread2.default)({}, point);
    },
    // 获取地图中心点位
    getMapCenter: function getMapCenter() {
      var point = this.map.getCenter();
      // console.log('center', point)
      // console.log('mapCenter', { ...this.mapCenter })
      this.sendAddressInfo.point = point;
      // this.setCenterPos(point)
      this.getAddressInfo(point);
    },
    // 地图点位解析地址信息
    getAddressInfo: function getAddressInfo(point) {
      var _this5 = this;
      this.geocoder.getLocation(point, function (res) {
        // console.log('getLocation', res)
        _this5.sendAddressInfo.address = res.address;
        _this5.sendPointInfo();
      });
    },
    sendPointInfo: function sendPointInfo() {
      // console.log('sendPointInfo', this.sendAddressInfo)
      this.$emit('sendPointInfo', this.sendAddressInfo);
    },
    // 获取地址坐标
    getAddressPoint: function getAddressPoint(address) {
      var _this6 = this;
      this.geocoder.getPoint(address, function (point) {
        // console.log('getPoint', point)
        // console.log('address', address)
        if (point) {
          _this6.setCenterPos(point);
          _this6.$nextTick(function () {
            _this6.getMapCenter();
          });
        }
      });
    },
    // 地址搜索
    remoteMethod: function remoteMethod() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      this.selectLoading = true;
      // console.log('remoteMethod', query)
      this.searchAddress = query;
    },
    // 处理地址搜索结果
    handlerSearch: function handlerSearch(res) {
      // console.log('handlerSearch', res)
      this.selectLoading = false;
      if (res) {
        this.selectAddressList = res.as;
      } else {
        this.selectAddressList = [];
      }
    },
    // 选中地址
    selectAddress: function selectAddress(e) {
      // console.log('selectAddress', e)
      var index = this.selectAddressList.findIndex(function (item) {
        return item.uid == e;
      });
      if (index > -1) {
        var item = this.selectAddressList[index];
        this.sendAddressInfo.address = item.address;
        this.sendAddressInfo.point = item.point;
        this.setCenterPos(this.sendAddressInfo.point);
        this.sendPointInfo();
      }
    }
  }
};
exports.default = _default2;