"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.commandTest = commandTest;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
// Mqtt调试

// Mqtt指令调试
function commandTest(params) {
  return _fetch.default.post('/Mqtt/Mqtt/commandTest', params, true);
}

// // 换电柜-充电开
// export function cabinetChargeOn(params) {
//     return http.post('/Mqtt/Mqtt/cabinetChargeOn', params, true)
// }

// // 换电柜-充电关
// export function cabinetChargeOff(params) {
//     return http.post('/Mqtt/Mqtt/cabinetChargeOff', params, true)
// }

// // 换电柜-电池仓开门
// export function cabinetOpenDoor(params) {
//     return http.post('/Mqtt/Mqtt/cabinetOpenDoor', params, true)
// }

// // 换电柜-查询上报信息
// export function cabinetQueryInfo(params) {
//     return http.post('/Mqtt/Mqtt/cabinetQueryInfo', params, true)
// }

// // 换电柜-设置换电柜屏幕显示
// export function setCabinetOSD(params) {
//     return http.post('/Mqtt/Mqtt/setCabinetOSD', params, true)
// }

// // 换电柜-查询电表度数
// export function electricMeterKWH(params) {
//     return http.post('/Mqtt/Mqtt/electricMeterKWH', params, true)
// }