"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.activeOrderGift = activeOrderGift;
exports.orderGiftList = orderGiftList;
exports.orderList = orderList;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/**
 * 订单管理
 */
// 套餐订单列表
function orderList(params) {
  return _fetch.default.post('/Supplier/Order/orderList', params);
}

// 礼包订单列表
function orderGiftList(params) {
  return _fetch.default.post('/Supplier/Order/orderGiftList', params);
}

// 激活礼包
function activeOrderGift(params) {
  return _fetch.default.post('/Supplier/Order/activeOrderGift', params, true);
}