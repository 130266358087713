"use strict";

var _interopRequireDefault = require("E:/ssz/xxz2/battery_iot/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
var _default2 = {
  components: {
    cropUpload: _cropUpload.default
  },
  props: {
    dialogTitle: {
      type: String
    },
    isInputVisible: {
      type: Boolean,
      default: false
    },
    handleDialog: {
      type: Boolean,
      default: false
    },
    customForm: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    tagsList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    cateList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  computed: {
    inputVisible: {
      get: function get() {
        return this.isInputVisible;
      },
      set: function set(newVal) {}
    }
  },
  data: function data() {
    var checkNum = function checkNum(rule, value, callback) {
      var reg = /^[0-9]\d*$/;
      if (!value) {
        callback(new Error("请输入数字"));
      } else if (!reg.test(value)) {
        callback(new Error("请输入正整数"));
      } else {
        callback();
      }
    };
    return {
      inputValue: '',
      rules: {
        category_id: [{
          required: true,
          message: "请选择品类",
          trigger: "change"
        }],
        field_order: [{
          required: true,
          message: "请输入数字",
          trigger: "blur"
        }, {
          validator: checkNum,
          trigger: 'blur'
        }],
        field_title: [{
          required: true,
          message: "请输入字段描述",
          trigger: "blur"
        }, {
          min: 1,
          max: 20,
          message: "长度在 1 到 20 个字符",
          trigger: "blur"
        }],
        field_type: [{
          required: true,
          message: "请选择字段类型",
          trigger: "change"
        }],
        field_val: [{
          required: false,
          message: "请输入字段值",
          trigger: "change"
        }],
        must_is: [{
          required: true,
          message: "请选择必填项",
          trigger: "change"
        }]
      },
      selectProps: {
        label: 'name',
        value: 'id'
      }
    };
  },
  methods: {
    closeDialog: function closeDialog(param) {
      this.$emit("closeDialog", param);
    },
    submitForm: function submitForm(formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this.$emit("submitForm", true);
        } else {
          return false;
        }
      });
    },
    closeTag: function closeTag(tag) {
      this.$emit("closeTag", tag);
    },
    changeCascader: function changeCascader(val) {
      if (val) {
        this.$emit("changeCascader", val[1]);
      }
    },
    showInput: function showInput(val) {
      this.$emit("showInput", val);
    },
    tagInputConfirm: function tagInputConfirm() {
      var inputValue = this.inputValue;
      if (inputValue) {
        if (this.tagsList.indexOf(inputValue) > -1) {
          this.$message.warning('不能重复添加');
        } else {
          this.tagsList.push(inputValue);
        }
      }
      this.inputVisible = false;
      this.inputValue = "";
      this.$emit('tagInputConfirm', this.tagsList);
    }
  }
};
exports.default = _default2;